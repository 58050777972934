import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Work_experience_action } from "../../../redux/action/action";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchIcon from "@rsuite/icons/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { styled } from "@mui/material/styles";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Work_experience = () => {
  const [title, setTitle] = useState([]);
  const [year, setYear] = useState([]);
  const [description, setDescription] = useState([]);
  const [inputCount, setInputCount] = useState(1);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.cv.cv1_details);
  const title_data = data ? data.experiencetitle : "";
  const year_data = data ? data.experience_year : "";
  const description_data = data ? data.experience_description : "";

  useEffect(() => {
    if (title_data || year_data || description_data) {
      setInputCount(
        title_data.length || year_data.length || description_data.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data);
      setYear(year_data);
      setDescription(description_data); // Initialize skillsData with ss
    }
  }, [title_data, year_data, description_data]);
  // For cv2
  const data2 = useSelector((state) => state.cv.cv2_details);
  const title_data2 = data2 ? data2.experiencetitle : "";
  const year_data2 = data2 ? data2.experience_year : "";
  const description_data2 = data2 ? data2.experience_description : "";

  useEffect(() => {
    if (title_data2 || year_data2 || description_data2) {
      setInputCount(
        title_data2.length || year_data2.length || description_data2.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data2);
      setYear(year_data2);
      setDescription(description_data2); // Initialize skillsData with ss
    }
  }, [title_data2, year_data2, description_data2]);

  // For cv3
  const data3 = useSelector((state) => state.cv.cv3_details);
  const title_data3 = data3 ? data3.experiencetitle : "";
  const year_data3 = data3 ? data3.experience_year : "";
  const description_data3 = data3 ? data3.experience_description : "";

  useEffect(() => {
    if (title_data3 || year_data3 || description_data3) {
      setInputCount(
        title_data3.length || year_data3.length || description_data3.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data3);
      setYear(year_data3);
      setDescription(description_data3); // Initialize skillsData with ss
    }
  }, [title_data3, year_data3, description_data3]);

  // For cv4
  const data4 = useSelector((state) => state.cv.cv4_details);
  const title_data4 = data4 ? data4.experiencetitle : "";
  const year_data4 = data4 ? data4.experience_year : "";
  const description_data4 = data4 ? data4.experience_description : "";

  useEffect(() => {
    if (title_data4 || year_data4 || description_data4) {
      setInputCount(
        title_data4.length || year_data4.length || description_data4.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data4);
      setYear(year_data4);
      setDescription(description_data4); // Initialize skillsData with ss
    }
  }, [title_data4, year_data4, description_data4]);

  // For cv5
  const data5 = useSelector((state) => state.cv.cv5_details);
  const title_data5 = data5 ? data5.experiencetitle : "";
  const year_data5 = data5 ? data5.experience_year : "";
  const description_data5 = data5 ? data5.experience_description : "";

  useEffect(() => {
    if (title_data5 || year_data5 || description_data5) {
      setInputCount(
        title_data5.length || year_data5.length || description_data5.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data5);
      setYear(year_data5);
      setDescription(description_data5); // Initialize skillsData with ss
    }
  }, [title_data5, year_data5, description_data5]);

  // For cv6
  const data6 = useSelector((state) => state.cv.cv6_details);
  const title_data6 = data6 ? data6.experiencetitle : "";
  const year_data6 = data6 ? data6.experience_year : "";
  const description_data6 = data6 ? data6.experience_description : "";

  useEffect(() => {
    if (title_data6 || year_data6 || description_data6) {
      setInputCount(
        title_data6.length || year_data6.length || description_data6.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data6);
      setYear(year_data6);
      setDescription(description_data6); // Initialize skillsData with ss
    }
  }, [title_data6, year_data6, description_data6]);

  // For cv7
  const data7 = useSelector((state) => state.cv.cv7_details);
  const title_data7 = data7 ? data7.experiencetitle : "";
  const year_data7 = data7 ? data7.experience_year : "";
  const description_data7 = data7 ? data7.experience_description : "";

  useEffect(() => {
    if (title_data7 || year_data7 || description_data7) {
      setInputCount(
        title_data7.length || year_data7.length || description_data7.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data7);
      setYear(year_data7);
      setDescription(description_data7); // Initialize skillsData with ss
    }
  }, [title_data7, year_data7, description_data7]);

  // For cv8
  const data8 = useSelector((state) => state.cv.cv8_details);
  const title_data8 = data8 ? data8.experiencetitle : "";
  const year_data8 = data8 ? data8.experience_year : "";
  const description_data8 = data8 ? data8.experience_description : "";

  useEffect(() => {
    if (title_data8 || year_data8 || description_data8) {
      setInputCount(
        title_data8.length || year_data8.length || description_data8.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data8);
      setYear(year_data8);
      setDescription(description_data8); // Initialize skillsData with ss
    }
  }, [title_data8, year_data8, description_data8]);

  // For cv9
  const data9 = useSelector((state) => state.cv.cv9_details);
  const title_data9 = data9 ? data9.experiencetitle : "";
  const year_data9 = data9 ? data9.experience_year : "";
  const description_data9 = data9 ? data9.experience_description : "";

  useEffect(() => {
    if (title_data9 || year_data9 || description_data9) {
      setInputCount(
        title_data9.length || year_data9.length || description_data9.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data9);
      setYear(year_data9);
      setDescription(description_data9); // Initialize skillsData with ss
    }
  }, [title_data9, year_data9, description_data9]);

  // For cv10
  const data10 = useSelector((state) => state.cv.cv10_details);
  const title_data10 = data10 ? data10.experiencetitle : "";
  const year_data10 = data10 ? data10.experience_year : "";
  const description_data10 = data10 ? data10.experience_description : "";

  useEffect(() => {
    if (title_data10 || year_data10 || description_data10) {
      setInputCount(
        title_data10.length || year_data10.length || description_data10.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data10);
      setYear(year_data10);
      setDescription(description_data10); // Initialize skillsData with ss
    }
  }, [title_data10, year_data10, description_data10]);

  // For cv11
  const data11 = useSelector((state) => state.cv.cv11_details);
  const title_data11 = data11 ? data11.experiencetitle : "";
  const year_data11 = data11 ? data11.experience_year : "";
  const description_data11 = data11 ? data11.experience_description : "";

  useEffect(() => {
    if (title_data11 || year_data11 || description_data11) {
      setInputCount(
        title_data11.length || year_data11.length || description_data11.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data11);
      setYear(year_data11);
      setDescription(description_data11); // Initialize skillsData with ss
    }
  }, [title_data11, year_data11, description_data11]);

  // For cv12
  const data12 = useSelector((state) => state.cv.cv12_details);
  const title_data12 = data12 ? data12.experiencetitle : "";
  const year_data12 = data12 ? data12.experience_year : "";
  const description_data12 = data12 ? data12.experience_description : "";

  useEffect(() => {
    if (title_data12 || year_data12 || description_data12) {
      setInputCount(
        title_data12.length || year_data12.length || description_data12.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data12);
      setYear(year_data12);
      setDescription(description_data12); // Initialize skillsData with ss
    }
  }, [title_data12, year_data12, description_data12]);

  // For cv13
  const data13 = useSelector((state) => state.cv.cv13_details);
  const title_data13 = data13 ? data13.experiencetitle : "";
  const year_data13 = data13 ? data13.experience_year : "";
  const description_data13 = data13 ? data13.experience_description : "";

  useEffect(() => {
    if (title_data13 || year_data13 || description_data13) {
      setInputCount(
        title_data13.length || year_data13.length || description_data13.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data13);
      setYear(year_data13);
      setDescription(description_data13); // Initialize skillsData with ss
    }
  }, [title_data13, year_data13, description_data13]);

  // For cv14
  const data14 = useSelector((state) => state.cv.cv14_details);
  const title_data14 = data14 ? data14.experiencetitle : "";
  const year_data14 = data14 ? data14.experience_year : "";
  const description_data14 = data14 ? data14.experience_description : "";

  useEffect(() => {
    if (title_data14 || year_data14 || description_data14) {
      setInputCount(
        title_data14.length || year_data14.length || description_data14.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data14);
      setYear(year_data14);
      setDescription(description_data14); // Initialize skillsData with ss
    }
  }, [title_data14, year_data14, description_data14]);

  // For cv15
  const data15 = useSelector((state) => state.cv.cv15_details);
  const title_data15 = data15 ? data15.experiencetitle : "";
  const year_data15 = data15 ? data15.experience_year : "";
  const description_data15 = data15 ? data15.experience_description : "";

  useEffect(() => {
    if (title_data15 || year_data15 || description_data15) {
      setInputCount(
        title_data15.length || year_data15.length || description_data15.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data15);
      setYear(year_data15);
      setDescription(description_data15); // Initialize skillsData with ss
    }
  }, [title_data15, year_data15, description_data15]);

  // For cv16
  const data16 = useSelector((state) => state.cv.cv16_details);
  const title_data16 = data16 ? data16.experiencetitle : "";
  const year_data16 = data16 ? data16.experience_year : "";
  const description_data16 = data16 ? data16.experience_description : "";

  useEffect(() => {
    if (title_data16 || year_data16 || description_data16) {
      setInputCount(
        title_data16.length || year_data16.length || description_data16.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data16);
      setYear(year_data16);
      setDescription(description_data16); // Initialize skillsData with ss
    }
  }, [title_data16, year_data16, description_data16]);

  // For cv17
  const data17 = useSelector((state) => state.cv.cv17_details);
  const title_data17 = data17 ? data17.experiencetitle : "";
  const year_data17 = data17 ? data17.experience_year : "";
  const description_data17 = data17 ? data17.experience_description : "";

  useEffect(() => {
    if (title_data17 || year_data17 || description_data17) {
      setInputCount(
        title_data17.length || year_data17.length || description_data17.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data17);
      setYear(year_data17);
      setDescription(description_data17); // Initialize skillsData with ss
    }
  }, [title_data17, year_data17, description_data17]);

  // For cv18
  const data18 = useSelector((state) => state.cv.cv18_details);
  const title_data18 = data18 ? data18.experiencetitle : "";
  const year_data18 = data18 ? data18.experience_year : "";
  const description_data18 = data18 ? data18.experience_description : "";

  useEffect(() => {
    if (title_data18 || year_data18 || description_data18) {
      setInputCount(
        title_data18.length || year_data18.length || description_data18.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data18);
      setYear(year_data18);
      setDescription(description_data18); // Initialize skillsData with ss
    }
  }, [title_data18, year_data18, description_data18]);

  // For cv19
  const data19 = useSelector((state) => state.cv.cv19_details);
  const title_data19 = data19 ? data19.experiencetitle : "";
  const year_data19 = data19 ? data19.experience_year : "";
  const description_data19 = data19 ? data19.experience_description : "";

  useEffect(() => {
    if (title_data19 || year_data19 || description_data19) {
      setInputCount(
        title_data19.length || year_data19.length || description_data19.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data19);
      setYear(year_data19);
      setDescription(description_data19); // Initialize skillsData with ss
    }
  }, [title_data19, year_data19, description_data19]);

  // For cv20
  const data20 = useSelector((state) => state.cv.cv20_details);
  const title_data20 = data20 ? data20.experiencetitle : "";
  const year_data20 = data20 ? data20.experience_year : "";
  const description_data20 = data20 ? data20.experience_description : "";

  useEffect(() => {
    if (title_data20 || year_data20 || description_data20) {
      setInputCount(
        title_data20.length || year_data20.length || description_data20.length
      ); // Update inputCount once ss is properly set
      setTitle(title_data20);
      setYear(year_data20);
      setDescription(description_data20); // Initialize skillsData with ss
    }
  }, [title_data20, year_data20, description_data20]);

  const handleTitleChange = (index, e) => {
    const newTitle = [...title];
    newTitle[index] = e.target.value;
    setTitle(newTitle);
  };
  const handleYearChange = (index, e) => {
    const newYear = [...year];
    newYear[index] = e.target.value;
    setYear(newYear);
  };
  const handleDescriptionChange = (index, e) => {
    const newDescription = [...description];
    newDescription[index] = e.target.value;
    setDescription(newDescription);
  };

  useEffect(() => {
    dispatch(Work_experience_action(title, year, description));
  }, [title, year, description, dispatch]);

  const addInputField = () => {
    setInputCount(inputCount + 1);
  };
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { t, i18n } = useTranslation();

  return (


<Accordion 
className='py-[25px]'
style={{ fontFamily: 'Poppins, sans-serif' }}
expanded={expanded === "panel11"}
onChange={handleChange("panel11")}
>
<AccordionSummary
  aria-controls="panel1d-content"
  id="panel1d-header"
  className='text-[28px]  cv-input-header font-[500] text-gray-700'
>
             
{t("Work_experience")}                  </AccordionSummary>
<AccordionDetails        style={{
            direction:
              i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
          }}    className="p-4 cv-input-content">
  {[...Array(inputCount)].map((_, index) => (
            <div key={index}>
              <p className="text-[25px] font-[500] my-[20px]">
              {t("Work_experience")}    : {index + 1}
              </p>
              <div className="flex mt-[10px]">
                <div className="fixed-height-input flex-1">
                  <label
                    htmlFor="inputname1"
                    className="block text-gray-800 font-semibold text-sm"
                  >
                    {t("Title")}  
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="inputname2"
                      className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={title[index] || ""} // Set value to skillsData[index] or an empty string if skillsData[index] is undefined
                      onChange={(e) => handleTitleChange(index, e)}
                    ></input>
                  </div>
                </div>
                <div className="fixed-height-input flex-1 ml-2"    style={{
                marginRight:
                  i18n.language === "ku" || i18n.language === "ar" ? "10px" : "0px",
              }} >
                  <label
                    htmlFor="inputname2"
                    className="block text-gray-800 font-semibold text-sm"
                  >
                    {t("years")}  
                    </label>
                  <div className="mt-2">
                    <select
                      type="text"
                      name="inputname2"
                      className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={year[index] || ""} // Set value to skillsData[index] or an empty string if skillsData[index] is undefined
                      onChange={(e) => handleYearChange(index, e)}
                    >
                      <option value="">{t("years")}  
                      </option>

                      <option value="1980">1980</option>
                      <option value="1981">1981</option>
                      <option value="1982">1982</option>
                      <option value="1983">1983</option>
                      <option value="1984">1984</option>
                      <option value="1985">1985</option>
                      <option value="1986">1986</option>
                      <option value="1987">1987</option>
                      <option value="1988">1988</option>
                      <option value="1989">1989</option>
                      <option value="1990">1990</option>
                      <option value="1991">1991</option>
                      <option value="1992">1992</option>
                      <option value="1993">1993</option>
                      <option value="1994">1994</option>
                      <option value="1995">1995</option>
                      <option value="1996">1996</option>
                      <option value="1997">1997</option>
                      <option value="1998">1998</option>
                      <option value="1999">1999</option>
                      <option value="2000">2000</option>
                      <option value="2001">2001</option>
                      <option value="2002">2002</option>
                      <option value="2003">2003</option>
                      <option value="2004">2004</option>
                      <option value="2005">2005</option>
                      <option value="2006">2006</option>
                      <option value="2007">2007</option>
                      <option value="2008">2008</option>
                      <option value="2009">2009</option>
                      <option value="2010">2010</option>
                      <option value="2011">2011</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2024">2025</option>
                      <option value="2024">2026</option>
                      <option value="2024">2027</option>
                      <option value="2024">2028</option>
                      <option value="2024">2029</option>
                      <option value="2024">2030</option>
                    </select>
                  </div>
                </div>
              </div>
              {/*another input*/}
              <h1 className="text-gray-800 Accordion-sub-title mt-[30px]">
              {t("Description")}  
              </h1>
              <div className="fixed-height-input">
                <textarea
                  className="input_add_freelancer h-[300px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={description[index] || ""} // Set value to skillsData[index] or an empty string if skillsData[index] is undefined
                  onChange={(e) => handleDescriptionChange(index, e)}
                />

                {/*another select*/}
              </div>
            </div>
          ))}
       
     <button onClick={addInputField} className="add-icon min-w-[220px] mt-6 rounded">
     <AddIcon/>
     {t("add_Work_experience")}  

     </button>

</AccordionDetails>
</Accordion>
  );
};

export default Work_experience;
