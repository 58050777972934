import React, { useEffect, useState } from "react";
import "../assets/style/auth.css";
import { useDispatch, useSelector } from "react-redux";
import { login, register } from "../redux/action/auth-action";
import { Link, useNavigate } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import { user_action } from "../redux/action/cv-action";
import Loading from "../sub component/show design data/loading";

const Register = () => {
  const [name, setName] = useState("");
  const [user_name, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [loginUrl, setLoginUrl] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(true);

  const token = useSelector((state) => state.cv.isAuthuntucated);
  const errors = useSelector((state) => state.auth.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(user_action());

        if(loginUrl){
          setLoading(false);
  
        }      } catch {}
    };
    fetchData();
  }, [dispatch,loginUrl]);

  const { t, i18n } = useTranslation();
  const [hasDispatched, setHasDispatched] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    if (!hasDispatched) {
      setHasDispatched(true);
      await dispatch(
        register(name, user_name, password, ConfirmPassword, navigate, t)
      );
      setTimeout(() => {
        setHasDispatched(false);
      }, 3000);
    }
  };
  const navigate = useNavigate();

  const [facebookUrl, setfacebookUrl] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/auth`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setLoginUrl(data.url))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/facebook_auth`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setfacebookUrl(data.url))
      .catch((error) => console.error(error));
  }, []);

  if (token) {
    navigate("/"); // Navigate to /home after the timeout
  }
  return (
    <>
      <Helmet>
        {/* Basic Meta Tags */}
        <title>Register - Karbein</title>
        <meta
          name="description"
          content="Create a new account on Karbein to start exploring job listings, services, and other career opportunities. Join us to manage your professional profile."
        />
        <meta
          name="keywords"
          content="Register, karbein register  Sign Up, Karbein, New Account, Job Listings, Services"
        />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Register - Karbein" />
        <meta
          property="og:description"
          content="Sign up for a Karbein account to explore job opportunities, manage your profile, and access various services. Join our platform to advance your career."
        />
        <meta
          property="og:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta property="og:url" content="https://karbein.com/Register" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Karbein" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Register - Karbein" />
        <meta
          name="twitter:description"
          content="Create a Karbein account to start exploring job listings and managing your professional profile. Register to join our platform and advance your career."
        />
        <meta
          name="twitter:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta name="twitter:url" content="https://karbein.com/Register" />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://karbein.com/Register" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "headline": "Register - Karbein",
        "description": "Sign up page for Karbein. Create a new account to manage your profile and explore job opportunities.",
        "url": "https://karbein.com/Register",
        "image": "https://karbein.com/images/Brand-photos.png",
        "publisher": {
          "@type": "Organization",
          "name": "Karbein",
          "logo": {
            "@type": "ImageObject",
            "url": "https://karbein.com/images/Brand-photos.png"
          }
        }
      }
    `}
        </script>
      </Helmet>

      {loading ? (
        <Loading />
      ) : (
        <div className="bg-gray-50">
          <div
            style={{
              direction:
                i18n.language === "ku" || i18n.language === "ar"
                  ? "rtl"
                  : "ltr",
            }}
            className="flex auth-container justify-center items-center min-h-screen  pt-[80px] "
          >
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 w-[600px]">
              <div className="mx-auto max-w-lg">
                <form
                  onSubmit={submit}
                  className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow sm:p-6 lg:p-8 bg-white"
                >
                  <p className="text-center text-lg font-medium">
                    {t("Sign_up_register")}{" "}
                  </p>
                  <div>
                  <label
                      htmlFor="email"
                      className="block mb-[5px] mx-[5px] text-sm font-medium text-gray-700"
                    >                      {t("name")}{" "}
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        className="w-full rounded-lg auth-input focus:border-gray-200 border-gray-200 p-3 pe-12 text-sm"
                        placeholder={t("name")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    {errors ? (
                      <div className="ml-[5px] pt-[8px]">
                        {errors && errors.name && (
                          <div className="text-red-500">
                            {errors.name == "name_required"
                              ? t("name_required")
                              : null}
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div>
                  <label
                      htmlFor="email"
                      className="block mb-[5px] mx-[5px] text-sm font-medium text-gray-700"
                    >
                      {t("email")}{" "}
                    </label>
                    <div className="relative">
                      <input
                        type="email"
                        name="email"
                        className="w-full rounded-lg auth-input focus:border-gray-200 border-gray-200 p-3 pe-12 text-sm"
                        placeholder={t("email")}
                        value={user_name}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </div>
                    {errors ? (
                      <div className=" pt-[8px]">
                        {errors && errors.email && (
                          <div className="text-red-500">
                            {errors.email == "email_unique"
                              ? t("email_already_taken")
                              : t("Email_required")}
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div>
                  <label
                      htmlFor="email"
                      className="block mb-[5px] mx-[5px] text-sm font-medium text-gray-700"
                    >
                      {t("Password")}{" "}
                    </label>
                    <div className=" border rounded-lg  flex justify-between items-center focus:border-gray-200 border-gray-200">
                      <input
                        type={showPassword ? "text" : "password"}
                        name={t("Password")}
                        className="w-full rounded-lg auth-input border-none p-3 pe-12 text-sm"
                        placeholder={t("Password")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          marginLeft:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "15px"
                              : "0px",
                          marginRight:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "0px"
                              : "15px",
                        }}
                      >
                        {showPassword ? (
                          <RemoveRedEyeOutlinedIcon
                            style={{ fontSize: "25px" }}
                            className="text-gray-400 pl-[3px]"
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{ fontSize: "25px" }}
                            className="text-gray-400 pl-[3px]"
                          />
                        )}
                      </div>
                    </div>
                    {errors ? (
                      <div className="pt-[8px]">
                        {errors && errors.password && (
                          <div className="text-red-500">
                            {errors.password ==
                            "The password field is required."
                              ? t("Password_required")
                              : errors.password ==
                                "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one digit."
                              ? t("password_atLeast")
                              : (errors.password =
                                  "The password field confirmation does not match."
                                    ? null
                                    : null)}
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>

                  <div>
                  <label
                      htmlFor="email"
                      className="block mb-[5px] mx-[5px] text-sm font-medium text-gray-700"
                    >
                      {t("password_confirm")}{" "}
                    </label>
                    <div className=" border rounded-lg  flex justify-between items-center focus:border-gray-200 border-gray-200">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        name={t("Password")}
                        className="w-full rounded-lg auth-input border-none p-3 pe-12 text-sm"
                        placeholder={t("password_confirm")}
                        // value={ConfirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <div
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        style={{
                          marginLeft:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "15px"
                              : "0px",
                          marginRight:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "0px"
                               : "15px",
                        }}
                      >
                        {showConfirmPassword ? (
                          <RemoveRedEyeOutlinedIcon
                            style={{ fontSize: "25px" }}
                            className="text-gray-400 pl-[3px]"
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{ fontSize: "25px" }}
                            className="text-gray-400 pl-[3px]"
                          />
                        )}
                      </div>
                    </div>
                    {errors ? (
                      <div className="text-red-500 pt-[8px] ">
                        {errors.password == "The password field is required."
                          ? null
                          : errors.password ==
                            "Password must be at least 8 characters and include at least one uppercase letter, one lowercase letter, and one digit."
                          ? null
                          : (errors.password =
                              "The password field confirmation does not match."
                                ? t("password_not_match")
                                : null)}
                      </div>
                    ) : null}
                  </div>

                  <div className="text-right h-[20px]"></div>
                  <button
                    type="submit"
                    className="block w-full rounded-lg bg-[#ffb923] px-5 py-3 text-sm font-medium text-white hover:bg-[#ffc64a]"
                  >
                    {t("Sign_up_button")}
                  </button>
                  <div className="text-center mt-4 flex flex-col gap-[5px]">
                    <p className="text-sm text-gray-500 mb-[15px]">
                      {t("Or")}{" "}
                    </p>
                    <a
                      href={loginUrl}
                      type="button"
                      className="flex hover:text-black items-center  justify-center w-full rounded-lg px-5 py-3 text-sm font-medium border text-gray-500  mb-2"
                    >
                      <FcGoogle
                        style={{
                          marginLeft:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "5px"
                              : "0px",
                          marginRight:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "0px"
                              : "5px",
                        }}
                        size={20}
                      />
                      {t("Login_with_Google")}{" "}
                    </a>
                
                  </div>

                  <p className="text-center text-sm text-gray-500">
                    {t("have_acount")}
                    <a href={"/login"} className="underline">
                      {t("Sign_in")}{" "}
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default Register;
