import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Skills_data = ({
  third_content_bg_color,
  ten_content_bg_color,
  list_black,
}) => {
  const skills = useSelector((state) => state.main.skills);
  const font_size = useSelector((state) => state.open.font_size);
  const bg_header_color = useSelector((state) => state.open.selectedColor);
  const [isSmallSize, setIsSmallSize] = useState(false);
  const url = ["/history"];
  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    if (url.includes(path)) {
      setIsSmallSize(true);
    } else {
      setIsSmallSize(false);
    }
  });
  const { t, i18n } = useTranslation();

  return (
    <div
      className={
        isSmallSize
          ? "text-[7px] max-w-[175px] mt-[-10px]"
          : "max-w-[200px] mt-[-27px] text-[15px]"
      }
      style={{ fontSize: `${font_size}` }}
    >
      {skills && skills.length ? ( // Check if skills is truthy and is an array with elements
        <div
          className={
            third_content_bg_color || ten_content_bg_color ? "text-center" : ""
          }
        >
          <p
            className={
              isSmallSize
                ? "text-[10px] mb-[-8px] font-bold"
                : third_content_bg_color
                ? `mb-[10px]   border-[2px]  rounded-[20px] font-bold`
                : ten_content_bg_color
                ? "border-[2px] mb-[20px] font-bold"
                : "bg-color rounded-[20px] font-bold"
            }
            style={{
              border:
                third_content_bg_color || ten_content_bg_color
                  ? `1px solid ${bg_header_color}`
                  : null,
              third_content_bg_color,
            }}
          >
Skills          </p>
          <hr
            className={`my-[10px] ${
              third_content_bg_color || ten_content_bg_color ? "d-none" : ""
            }`}
          />{" "}
        </div>
      ) : null}
      <div className="flex gap-2  flex-wrap  ">
        {skills && Array.isArray(skills) ? ( // Check if skills is an array before mapping
          skills.map((skill, index) => (
            <div key={index} className="">
              <div
                className={
                  isSmallSize
                    ? "max-w-[75px] break-words"
                    : "max-w-[200px] flex gap-2 leading-[22px]  break-words"
                }
              >
                <span
                  className={
                    list_black
                      ? "max-h-[5px] mt-[10px] min-w-[5px] bg-black rounded-full"
                      : "max-h-[5px] mt-[10px] min-w-[5px] bg-white rounded-full"
                  }
                >
                  {" "}
                </span>
                <span className=" max-w-[180px] break-words">{skill}</span>
              </div>
            </div>
          ))
        ) : (
          <div>No skills available</div>
        )}
      </div>
    </div>
  );
};

export default Skills_data;
