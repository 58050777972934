import Cleaning from "../../components/services/cleaning";
import Vehicle_Maintenance from "../../components/services/Vehicle-Maintenance";
import Electrical from "../../components/services/electrical";
import Freight_Forwarding from "../../components/services/Freight_Forwarding";
import Nail from "../../components/services/Nail";
import Makeup from "../../components/services/Makeup";
import Treatments from "../../components/services/Treatments";
import Hairdressing from "../../components/services/Hairdressing";
import Car_Washing from "../../components/services/Car_Wash";
import Tire_Replacement from "../../components/services/Tire_Replacement";
import Garden_Maintenance from "../../components/services/Garden_Maintenance";
import Construction_of_houses from "../../components/services/Construction_of_houses";
import { Link } from "react-router-dom";
import Photography from "../../components/services/Photography";
import Travel from "../../components/services/travel";
import { useTranslation } from "react-i18next";
import Carpet from "../../components/services/Carpet";

const Maintenance_and_Services = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="my-[170px] max-w-[1800px]  mx-auto services-container">
      <h1 className="text-center works_title   mb-[85px]  leading-tight font-[500]  tracking-tight text-gray-900 ">
      {t("Services")}      
      </h1>
      <div>
        <div className="flex  justify-center  ">
          <div className="main-freeLancer-container   main-freeLancer-details-container   min-w-[90%]  max-w-[90%]  flex  justify-center    gap-[40px] ">
            <div className="flex flex-col ">
              <div className="grid grid-cols-1 services-grid sm:grid-cols-4 gap-4  p- ">
                <Cleaning />
                <Garden_Maintenance />
                <Travel />

                <Vehicle_Maintenance />
                <Car_Washing />
                {/* <Tire_Replacement /> */}

                <div className="hidden-largrge-screen">
                  <Hairdressing />
                </div>
                <Makeup />

                <div className="hidden-largrge-screen">
                <Carpet />
                </div>
                {/* <Nail /> */}
                <div className="hidden-largrge-screen">
                  {/* <Photography /> */}
                </div>
                {/* <Travel /> */}
                {/* <Home_ApplianceRepairs />
                  <Garden_Maintenance />
                  <Electronic_Services />
                  <Post />
          
                  <Veterinary />
                  <Mortgage />
                  <LegalConsultation />
                  <Photography />
                  <Battery_Services /> */}
              </div>
              <div className="flex   justify-end ">
          <Link
            to={"/services"}
            className="relative w-[140px] h-[56px] hover:text-gray-500 overflow-hidden border-none text-black bg-none pb-8 cursor-pointer group"
          >
            <div className="absolute inset-0 flex justify-center items-center clone">
              <span className="ml-[50px] underline">{t('Show_all')}</span>
            </div>
          
          </Link>
        </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance_and_Services;
