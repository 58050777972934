import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const JOB_GET = "JOB_GET";
export const job_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(`${process.env.REACT_APP_URL}/api/job/getAll`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
// get tootal jobs  data for showing paginations 
const total_jobs = response.data.data.total;
      dispatch({ type: JOB_GET, payload: { data, total_pages,total_jobs } });
    } catch (error) {
    }
  };
};

export const JOB_INSERT = "JOB_INSERT";
export const job_insert = (
  image,
  job_title,
  funcrional_area,
  industry,
  skill,
  gender,

  country,
  state,
  city,
  company_name,
  job_type,
  Phone,
  Email,
  Salary,
  Certifications,
  Period,
  currency,
  Experience,
  description,
  t,
  navigate
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const formData = new FormData();
      formData.append("image", image);

      formData.append("job_title", job_title);
      formData.append("description", description);
      formData.append("gender", gender);

      formData.append("funcrional_area", funcrional_area);
      formData.append("industry", industry);
      formData.append("skill", skill);
      formData.append("country", country);
      formData.append("state", state);
      formData.append("city", city);

      formData.append("company_name", company_name);
      formData.append("job_type", job_type);
      formData.append("Phone", Phone);
      formData.append("Email", Email);
      formData.append("Salary", Salary);
      formData.append("Certifications", Certifications);
      formData.append("Period", Period);
      formData.append("currency", currency);
      formData.append("Experience", Experience);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/job/insert`,

        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const status = response.data.data;

      dispatch({ type: JOB_INSERT, payload: { response, status } });
      toast.success(t("Created_successfully")); // Using translation function passed as parameter

    } catch (error) {
      toast.error(t("error")); // Using translation function passed as parameter

    }
  };
};

export const JOB_UPDATE = "JOB_UPDATE";
export const job_update = (
  id,
  image,
  job_title,
  funcrional_area,
  industry,
  skill,
  gender,

  company_name,
  job_type,
  Phone,
  Email,
  Salary,
  Certifications,
  Period,
  currency,
  Experience,
  description,
  t,
  navigate
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const formData = new FormData();
      formData.append("image", image);

      formData.append("job_title", job_title);
      formData.append("description", description);

      formData.append("funcrional_area", funcrional_area);
      formData.append("industry", industry);
      formData.append("skill", skill);
      // formData.append("country", country);
      // formData.append("state", state);
      // formData.append("city", city);
      formData.append("gender", gender);

      formData.append("company_name", company_name);
      formData.append("job_type", job_type);
      formData.append("Phone", Phone);
      formData.append("Email", Email);
      formData.append("Salary", Salary);
      formData.append("Certifications", Certifications);
      formData.append("Period", Period);
      formData.append("currency", currency);
      formData.append("Experience", Experience);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/job/update/${id}`,

        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const status = response.data.data;

      dispatch({ type: JOB_UPDATE, payload: { response, status } });
      toast.success(t("Updated_successfully")); // Using translation function passed as parameter
      //   setTimeout(() => {
      //     navigate("/services/Profile"); // Navigate to /home after the timeout
      // }, 2000);
    } catch (error) {
      toast.error(t("error")); // Using translation function passed as parameter
    }
  };
};

export const JOB_DELETE = "JOB_DELETE";
export const job_delete = (id, t) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/job/delete/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const status = response.data.data;

      dispatch({ type: JOB_UPDATE, payload: { response, status } });
      //   setTimeout(() => {
      //     navigate("/services/Profile"); // Navigate to /home after the timeout
      // }, 2000);
    } catch (error) {}
  };
};

// henanaway datakan bo neshandani la search
export const JOB__SERACH_GET = "JOB__SERACH_GET";
export const job_search_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/job/searchData`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: JOB__SERACH_GET, payload: { data, total_pages } });
    } catch (error) {
    }
  };
};

// export const JOB_SEARCH = "JOB_SEARCH";
// export const search_job = (search, page) => {
//   return async (dispatch) => {
//     try {
//       const token = Cookies.get("jwt");
//       const response = await axios.get(
//         `http://localhost:8000/api/job/getAll?titleSearch=${search}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       const data = response.data.data.data;
//       const total_pages = response.data.total_pages;

//       dispatch({ type: JOB_SEARCH, payload: { data, total_pages } });
//     } catch (error) {
//       dispatch({ type: JOB_SEARCH, payload: error });
//     }
//   };
// };
export const FUNCTIONALAREA_JOB_SEARCH = "JOB_SEARCH";
export const functionalAreaSearch_job = (search) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/job/getAll?functionalAreaSearch=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: FUNCTIONALAREA_JOB_SEARCH,
        payload: { data, total_pages },
      });
    } catch (error) {
      dispatch({ type: FUNCTIONALAREA_JOB_SEARCH, payload: error });
    }
  };
};
export const INDUSTRY_JOB_SEARCH = "INDUSTRY_JOB_SEARCH";
export const industrySearch_job = (search) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/job/getAll?industrySearch=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: INDUSTRY_JOB_SEARCH, payload: { data, total_pages } });
    } catch (error) {
      dispatch({ type: INDUSTRY_JOB_SEARCH, payload: error });
    }
  };
};
export const SKILL_JOB_SEARCH = "SKILL_JOB_SEARCH";
export const skillSearch_job = (search) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/job/getAll?skillSearch=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: SKILL_JOB_SEARCH, payload: { data, total_pages } });
    } catch (error) {
      dispatch({ type: SKILL_JOB_SEARCH, payload: error });
    }
  };
};
export const COUNTRY_JOB_SEARCH = "FUCKSEARCH";
export const countrySearch_job = (search) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/job/getAll?countrySearch=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: COUNTRY_JOB_SEARCH, payload: { data, total_pages } });
    } catch (error) {
      dispatch({ type: COUNTRY_JOB_SEARCH, payload: error });
    }
  };
};
export const CITY_JOB_SEARCH = "CITY_JOB_SEARCH";
export const citySearch_job = (search) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/job/getAll?citySearch=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({
        type: COMPANY_NAME_JOB_SEARCH,
        payload: { data, total_pages },
      });
    } catch (error) {
      dispatch({ type: COMPANY_NAME_JOB_SEARCH, payload: error });
    }
  };
};
export const COMPANY_NAME_JOB_SEARCH = "COMPANY_NAME_JOB_SEARCH";
export const company_nameSearch_job = (search) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/job/getAll?companyNameSearch=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: CITY_JOB_SEARCH, payload: { data, total_pages } });
    } catch (error) {
      dispatch({ type: CITY_JOB_SEARCH, payload: error });
    }
  };
};

// ama bo serachakaya tanha bo awama countakan bgore

export const JOB_SEARCH_CHANGE = "JOB_SEARCH_CHANGE";
export const job_search_change_data = (
  companyNameSearch = "",
  functionalAreaSearch = "",
  industrySearch = "",
  search = "",
  countrySearch = "",
  stateSearch = "",
  citySearch = "",
  ExperienceSearch = "",
  CertificationsSearch = "",
  typeSearch = ""
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/job/countSearchData?companyNameSearch=${companyNameSearch}&functionalAreaSearch=${functionalAreaSearch}&industrySearch=${industrySearch}&CertificationsSearch=${CertificationsSearch}&typeSearch=${typeSearch}&ExperienceSearch=${ExperienceSearch}&titleSearch=${search}&stateSearch=${stateSearch}&citySearch=${citySearch}&citySearch=${citySearch}&countrySearch=${countrySearch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: JOB_SEARCH_CHANGE, payload: { data, total_pages } });
    } catch (error) {
    }
  };
};

export const JOB_SEARCH = "JOB_SEARCH";
export const job_search_data = (
  companyNameSearch = "",
  functionalAreaSearch = "",
  industrySearch = "",
  search = "",
  countrySearch = "",
  stateSearch = "",
  citySearch = "",
  ExperienceSearch = "",
  CertificationsSearch = "",
  typeSearch = ""
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/job/getAll?companyNameSearch=${companyNameSearch}&functionalAreaSearch=${functionalAreaSearch}&industrySearch=${industrySearch}&CertificationsSearch=${CertificationsSearch}&typeSearch=${typeSearch}&ExperienceSearch=${ExperienceSearch}&titleSearch=${search}&stateSearch=${stateSearch}&citySearch=${citySearch}&citySearch=${citySearch}&countrySearch=${countrySearch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: JOB_SEARCH, payload: { data, total_pages } });
    } catch (error) {
    }
  };
};

export const JOB_PAGE = "JOB_PAGE";
export const page_job = (
  page,
  search = "",
  functionalAreaSearch = "",
  industrySearch = "",
  companyNameSearch = "",
  stateSearch,
  citySearch = "",
  countrySearch,
  ExperienceSearch = "",
  CertificationsSearch = "",
  typeSearch = ""
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/job/getAll?page=${page}&companyNameSearch=${companyNameSearch}&titleSearch=${search}&functionalAreaSearch=${functionalAreaSearch}&ExperienceSearch=${ExperienceSearch}&titleSearch=${search}&functionalAreaSearch=${functionalAreaSearch}&CertificationsSearch=${CertificationsSearch}&industrySearch=${industrySearch}&typeSearch=${typeSearch}&citySearch=${citySearch}&stateSearch=${stateSearch}&countrySearch=${countrySearch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({ type: JOB_PAGE, payload: { data } });
    } catch (error) {
    }
  };
};

//get search value to using in pagination
export const JOBSEARCHVALUE = "SEARCHVALUE";
export const job_search_value = (search) => {
  return {
    type: JOBSEARCHVALUE,
    search: search,
  };
};
//functional_areae
export const FUNCTIONAL_AREA_SEARCHVALUE = "FUNCTIONAL_AREA_SEARCHVALUE";
export const functional_area_search_value = (search) => {
  return {
    type: FUNCTIONAL_AREA_SEARCHVALUE,
    search: search,
  };
};
//industry
export const INDUSTRY_SEARCHVALUE = "INDUSTRY_SEARCHVALUE";
export const industry_search_value = (search) => {
  return {
    type: INDUSTRY_SEARCHVALUE,
    search: search,
  };
};
// skill
export const STATE_SEARCHVALUE = "SKILL_SEARCHVALUE";
export const state_search_value = (search) => {
  return {
    type: STATE_SEARCHVALUE,
    search: search,
  };
};
// country
export const COUNTRY_SEARCHVALUE = "COUNTRY_SEARCHVALUE";
export const country_search_value = (search) => {
  return {
    type: COUNTRY_SEARCHVALUE,
    search: search,
  };
};
// city
export const CITY_SEARCHVALUE = "CITY_SEARCHVALUE";
export const city_search_value = (search) => {
  return {
    type: CITY_SEARCHVALUE,
    search: search,
  };
};
// company_name
export const COMPANY_NAME_SEARCHVALUE = "COMPANY_NAME_SEARCHVALUE";
export const company_name_search_value = (search) => {
  return {
    type: COMPANY_NAME_SEARCHVALUE,
    search: search,
  };
};

export const EXPERIENCE_SEARCHVALUE = "EXPERIENCE_SEARCHVALUE";
export const experience_search_value = (search) => {
  return {
    type: EXPERIENCE_SEARCHVALUE,
    search: search,
  };
};

export const TYPE_SEARCHVALUE = "TYPE_SEARCHVALUE";
export const type_search_value = (search) => {
  return {
    type: TYPE_SEARCHVALUE,
    search: search,
  };
};

export const CERTIFICATION_SEARCHVALUE = "CERTIFICATION_SEARCHVALUE";
export const certification_search_value = (search) => {
  return {
    type: CERTIFICATION_SEARCHVALUE,
    search: search,
  };
};

//job details
export const JOB_DETAILS = "JOB_DETAILS";
export const job_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/job/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;

      dispatch({ type: JOB_DETAILS, payload: { data } });
    } catch (error) {
    }
  };
};
