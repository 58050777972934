import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const FREELANCERINSERT = "FREELANCERINSERT";

export const freelancer_insert = (
  image,
  name,
  email,
  phone,
  country,
  state,
  city,
  experience,
  description,
  functional_area,
  skills,
  per_hour_price,
  currency,
  t,
  navigate
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get('jwt');
      const formData = new FormData();
      formData.append('image', image);
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('description', description);
      formData.append('country', country);
      formData.append('state', state);
      formData.append('city', city);
      formData.append('experience', experience);
      formData.append('functional_area', functional_area);
      formData.append('skills', skills);
      formData.append('per_hour_price', per_hour_price);
      formData.append('currency', currency);

      const response = await axios.post(
       ` ${process.env.REACT_APP_URL}/api/freelancer/insert`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include', // include credentials if needed
        }
      );
     const  isInserted=true
      const status = response.data.data;
      dispatch({ type: FREELANCERINSERT, payload: { response, status },isInserted });
      toast.success(t('Created_successfully')); // Using translation function passed as parameter

  setTimeout(() => {
  navigate('/freelance/Profile'); // Navigate to /services/Profile after the timeout
}, 2000); 

    } catch (error) {
      const  isInserted=false

      // Handle error without logging to console
      toast.error(t('error')); // Using translation function passed as parameter
      dispatch({ type: FREELANCERINSERT, payload: { error: true } ,isInserted}); // Dispatch error action quietly
    }
  };
};

export const ADMIN_FREELANCERINSERT = "ADMIN_FREELANCERINSERT";

export const admin_freelancer_insert = (
  image,
  name,
  email,
  phone,
  country,
  state,
  city,
  experience,
  description,
  functional_area,
  skills,
  per_hour_price,
  currency,
  t,
  navigate
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get('jwt');
      const formData = new FormData();
      formData.append('image', image);
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('description', description);
      formData.append('country', country);
      formData.append('state', state);
      formData.append('city', city);
      formData.append('experience', experience);
      formData.append('functional_area', functional_area);
      formData.append('skills', skills);
      formData.append('per_hour_price', per_hour_price);
      formData.append('currency', currency);

      const response = await axios.post(
       ` ${process.env.REACT_APP_URL}/api/freelancer/insert`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include', // include credentials if needed
        }
      );
     const  isInserted=true
      const status = response.data.data;
      dispatch({ type: ADMIN_FREELANCERINSERT, payload: { response, status },isInserted });
      toast.success(t('Created_successfully')); // Using translation function passed as parameter



    } catch (error) {
      const  isInserted=false

      // Handle error without logging to console
      toast.error(t('error')); // Using translation function passed as parameter
      dispatch({ type: ADMIN_FREELANCERINSERT, payload: { error: true } ,isInserted}); // Dispatch error action quietly
    }
  };
};

//freelancer update
export const FREELANCERUPDATE = "FREELANCERUPDATE";
export const freelancer_update = (
  image,
  name,
  email,
  phone,
  experience,
  description,
  functional_area,
  skills,
  per_hour_price,
  currency,t
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const formData = new FormData();
            formData.append("image", image);

      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("experience", experience);
      formData.append("description", description);
      formData.append("functional_area", functional_area);
      formData.append("skills", skills);
      formData.append("per_hour_price", per_hour_price);
      formData.append("currency", currency);
   


      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/freelancer/update`,

              formData,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const status = response.data.data;

      dispatch({ type: FREELANCERUPDATE  , payload: { response, status } });
      toast.success(t("Updated_successfully"));

    } catch (error) {
      toast.error(t("error"));

    }
  };
};


//freelancer update
export const FREELANCER_ADMIN_UPDATE = "FREELANCER_ADMIN_UPDATE";
export const freelancer_admin__update = (
  id,
  image,
  name,
  email,
  phone,
  experience,
  description,
  functional_area,
  skills,
  per_hour_price,
  currency,t
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const formData = new FormData();
            formData.append("image", image);

      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("experience", experience);
      formData.append("description", description);
      formData.append("functional_area", functional_area);
      formData.append("skills", skills);
      formData.append("per_hour_price", per_hour_price);
      formData.append("currency", currency);
   


      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/freelancer/update/admin/${id}`,

              formData,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const status = response.data.data;

      dispatch({ type: FREELANCERINSERT, payload: { response, status } });
      toast.success(t("Updated_successfully"));

    } catch (error) {
          toast.error(t("error"));

    }
  };
};
export const FREELANCE_DELETE = "FREELANCE_DELETE";
export const freelance_delete = (
  id,

) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      
      const response = await axios.post(
      `${process.env.REACT_APP_URL}/api/freelancer/delete/${id}`,
{},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        {
          credentials: "include",
        }
      );
      const status = response.data.data;

      dispatch({ type: FREELANCE_DELETE, payload: { response, status } });
    //   setTimeout(() => {
    //     navigate("/services/Profile"); // Navigate to /home after the timeout
    // }, 2000); 
    } catch (error) {
    }
  };
};

export const FREELANCER_GET = "FREELANCER_GET";
export const freelancer_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/freelancer/getAll`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;
      // get tootal freelance data for showing paginations 
      const total_freelancers = response.data.data.total;

      dispatch({ type: FREELANCER_GET, payload: { data, total_pages ,total_freelancers } });
   
    } catch (error) {

    }
  };
};


export const FREELANCER_SEARCH_GET = "FREELANCER_SEARCH_GET";
export const freelancer_search_get = () => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
       `${process.env.REACT_APP_URL}/api/freelancer/getSearch`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: FREELANCER_SEARCH_GET, payload: { data, total_pages } });
    } catch (error) {
    }
  };
};
//freeelancer update details
export const FREELANCERUPDATEDETAILS = "FREELANCERUPDATEDETAILS";
export const freelancer_update_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/freelancer/update/details`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;
      dispatch({ type: FREELANCERUPDATEDETAILS, payload: { data } });
    } catch (error) {
    }
  };
};

export const SEARCHFREELANCER = "SEARCHFREELANCER";
export const search_freelancer = (search="",functionalArea_search="",Experience_search="") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/freelancer/getAll?search=${search}&functionalArea_search=${functionalArea_search}&Experience_search=${Experience_search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;
      const total_pages = response.data.total_pages;

      dispatch({ type: SEARCHFREELANCER, payload: { data, total_pages } });
    } catch (error) {
      dispatch({ type: SEARCHFREELANCER, payload: error });
    }
  };
};

export const PAGEFREELANCER = "PAGEFREELANCER";
export const page_freeLancer = (page, search="",functionalArea_search="",Experience_search="") => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/freelancer/getAll?page=${page}&search=${search}&functionalArea_search=${functionalArea_search}&Experience_search=${Experience_search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data.data;

      dispatch({ type: PAGEFREELANCER, payload: { data } });
    } catch (error) {
    }
  };
};

//search parameter
export const SEARCHVALUE = "SEARCHVALUE";
export const search_value = (search) => {
  return {
    type: SEARCHVALUE,
    search: search,
  };
};

export const FUNCTIONAL_AREA_SEARCHVALUE = "FUNCTIONAL_AREA_SEARCHVALUE";
export const functiona_area_search_value = (search) => {
  return {
    type: FUNCTIONAL_AREA_SEARCHVALUE,
    search: search,
  };
};


export const EXPERIENCESEARCHVALUE = "EXPERIENCESEARCHVALUE";
export const experience_search_value = (search) => {
  return {
    type: EXPERIENCESEARCHVALUE,
    search: search,
  };
};
export const FREELANCER_DETAILS = "FREELANCER_DETAILS";
export const freelancer_details = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/freelancer/details/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.data;

      dispatch({ type: FREELANCER_DETAILS, payload: { data } });
    } catch (error) {
    }
  };
};


//review

export const  REVIEW_INSERT = "REVIEW_INSERT";

export const review_insert = (
  id,
  rating,
  comment,
  t
) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get('jwt');
      const formData = new FormData();
      formData.append('rating', rating);
      formData.append('comment', comment);
      const response = await axios.post(
       `${process.env.REACT_APP_URL}/api/freelancers/${id}/review`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include', // include credentials if needed
        }
      );

      const status = response.data.data;
      dispatch({ type: REVIEW_INSERT, payload: { response, status } });
      toast.success(t('Created_successfully')); // Using translation function passed as parameter

    } catch (error) {
      // Handle error without logging to console
      toast.error(t('error')); // Using translation function passed as parameter
      
      dispatch({ type: REVIEW_INSERT, payload: { error: true } }); // Dispatch error action quietly
      // console.clear()
    }
  };
};


//review get 
export const REVIEW_GET = "REVIEW_GET";
export const review_get = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/freelancers/${id}/average-review`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        } 
      );

      const data = response.data.reviews;
      const total_pages = response.data.pagination.last_page;

      const total_reviews = response.data.pagination.total;
  
      dispatch({ type: REVIEW_GET, payload: { data,total_pages,total_reviews } });
    } catch (error) {
    }
  };
};

//review get 
export const REVIEW_PAGE = "REVIEW_PAGE";
export const review_page = (id,page) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/freelancers/${id}/average-review?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        } 
      );

      const data = response.data.reviews;

      dispatch({ type: REVIEW_PAGE, payload: { data } });
    } catch (error) {
    }
  };
};
// henanaway review e user  bo har freelance ba pee id  bo nmuna au useray login krdwa ch review eke haya bo har freelance

export const USER_REVIEW_GET = "USER_REVIEW_GET";
export const user_review_get = (id) => {
  return async (dispatch) => {
    try {
      const token = Cookies.get("jwt");

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/user/reviews/freelancer/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data.review;

      dispatch({ type: USER_REVIEW_GET, payload: data });
    } catch (error) {
    }
  };
};




