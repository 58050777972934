import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Experience_data = ({ third_content_bg_color, ten_content_bg_color }) => {
  //work experience
  const experience_title = useSelector((state) => state.main.experience_title);
  const experience_year = useSelector((state) => state.main.experience_year);
  const experience_description = useSelector(
    (state) => state.main.experience_description
  );
  const bg_header_color = useSelector((state) => state.open.selectedColor);

  const font_size = useSelector((state) => state.open.font_size);
  const combine_Experience = (
    experience_title,
    experience_year,
    experience_description
  ) => {
    const mergedDates = [];
    for (
      let i = 0;
      i <
      Math.max(
        experience_title.length,
        experience_year.length,
        experience_description.length
      );
      i++
    ) {
      mergedDates.push({
        experience_title: experience_title[i],
        experience_year: experience_year[i],
        experience_description: experience_description[i],
      });
    }
    return mergedDates;
  };
  const [isSmallSize, setIsSmallSize] = useState(false);

  const location = useLocation();
  const url = ["/history"];
  const path = location.pathname;
  useEffect(() => {
    if (url.includes(path)) {
      setIsSmallSize(true);
    } else {
      setIsSmallSize(false);
    }
  });
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        className={
          isSmallSize
            ? "text-[10px] mt-[20px] max-w-[75px]"
            : "flex flex-col mt-[20px] "
        }
        style={{ fontSize: `${font_size}` }}
      >
        {experience_title.length ||
        experience_year.length ||
        experience_description.length ? (
          <div
            className={
              third_content_bg_color || ten_content_bg_color
                ? "text-center"
                : null
            }
          >
            {" "}
            <p
              className={
                third_content_bg_color
                  ? `mb-[10px] text-white border-[2px] rounded-[20px] font-bold`
                  : isSmallSize
                  ? "text-[10px] mb-[-8px] font-bold"
                  : ten_content_bg_color
                  ? " mb-[20px] border-[2px] text-white font-bold "
                  : "bg-color rounded-[20px] font-bold"
              }
              // `1px solid ${bg_header_color}`
              style={{
                backgroundColor:
                  third_content_bg_color || ten_content_bg_color
                    ? ` ${bg_header_color}`
                    : null,
                third_content_bg_color,
              }}
            >
              {" "}
              Work experience
            </p>{" "}
             <hr
              className={`my-[10px] ${
                third_content_bg_color || ten_content_bg_color ? "d-none" 
:
                          isSmallSize ? "min-w-[140px]" :null
              }`}
            />{" "}
          </div>
        ) : null}
        {combine_Experience(
          experience_title,
          experience_year,
          experience_description
        ).map((combined, index) => (
          <div key={index} className="flex flex-col  gap-2">
            <div className="flex justify-between ">
            <div className="max-w-[90%] break-words truncate font-bold  ">
            {combined.experience_title}{" "}
              </div>

              <p className="max-w-[500px] break-words	">
                {combined.experience_year}
              </p>
            </div>
            <p className="max-w-[500px] break-words ">{combined.scholl}</p>
            <p
              className={
                isSmallSize ? "truncate " : "max-w-[500px] break-words	"
              }
            >
              {combined.experience_description}
            </p>
            <br />
          </div>
        ))}
      </div>
    </>
  );
};

export default Experience_data;
