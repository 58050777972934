import React, { useEffect, useState } from "react";
import "../assets/style/auth.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/action/auth-action";
import { Link, useNavigate } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

import {
  auth,
  googleProvider,
  facebookProvider,
  signInWithPopup,
} from "../firebaseConfig";
import Loading from "../sub component/show design data/loading";
import { user_action } from "../redux/action/cv-action";

const Login = () => {
  const [user_name, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loginUrl, setLoginUrl] = useState(null);//google login url

  //   const handleGoogleLogin = async () => {
  //     try {
  //       const result = await signInWithPopup(auth, googleProvider);
  //       const user = result.user;
  //       const token = await result.user.getIdToken();  // Retrieve the token

  //       // Set the token in a cookie
  //       Cookies.set("jwt", token, { expires: 7 }); // Expires in 7 days

  //       console.log("Google login successful:", user);
  //     } catch (error) {
  //       console.error("Google login error:", error);
  //     }
  //   };

  //   const handleFacebookLogin = async () => {
  //     try {
  //       const result = await signInWithPopup(auth, facebookProvider);
  //       const user = result.user;
  //       const token = await user.getIdToken(); // Get the authentication token

  //       // Set the token in a cookie
  //       Cookies.set("token", token, { expires: 7 }); // Expires in 7 days

  //       console.log("Facebook login successful:", user);
  //     } catch (error) {
  //       console.error("Facebook login error:", error);
  //     }
  //   };
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());

      if(loginUrl){
        setLoading(false);

      }
    };
    fetchData();
  }, [dispatch,loginUrl]);

  const navigate = useNavigate();
  const token = useSelector((state) => state.cv.isAuthuntucated);
  const errors = useSelector((state) => state.auth.errors);
  if (token) {
    navigate("/");
  }
  const { t, i18n } = useTranslation();
  const [hasDispatched, setHasDispatched] = useState(false);
  const submit = async (e) => {
    e.preventDefault();
    if (!hasDispatched) {
      setHasDispatched(true);
      await dispatch(login(user_name, password, navigate, t));
      setTimeout(() => {
        setHasDispatched(false);
      }, 4000);
    }

  };
  useEffect(() => {
    console.clear();
  }, [setLoading]);
  const [facebookUrl, setfacebookUrl] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/auth`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setLoginUrl(data.url))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL}/api/facebook_auth`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setfacebookUrl(data.url))
      .catch((error) => console.error(error));
  }, []);

  return (
    <>
 <Helmet>
        {/* Basic Meta Tags */}
        <title>Login - Karbein</title>
        <meta
          name="description"
          content="Login to your Karbein account to access job listings, services, and more. Securely sign in to manage your profile and explore opportunities."
        />
        <meta
          name="keywords"
          content="Login, karbein login , Sign In, Karbein , Account Access, Job Listings, Services"
        />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Login - Karbein" />
        <meta
          property="og:description"
          content="Access your Karbein account to explore job listings, manage your profile, and more. Secure login for managing your career opportunities."
        />
        <meta
          property="og:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta property="og:url" content="https://karbein.com/login" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Karbein" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Login - Karbein" />
        <meta
          name="twitter:description"
          content="Securely log in to your Karbein account to manage your profile and access job opportunities. Login to explore and manage your career."
        />
        <meta
          name="twitter:image"
          content="https://karbein.com/images/Brand-photos.png"
        />
        <meta name="twitter:url" content="https://karbein.com/login" />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://karbein.com/login" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "headline": "Login - Karbein",
        "description": "Secure login page for Karbein. Access job listings, services, and manage your profile.",
        "url": "https://karbein.com/login",
        "image": "https://karbein.com/images/Brand-photos.png",
        "publisher": {
          "@type": "Organization",
          "name": "Karbein",
          "logo": {
            "@type": "ImageObject",
            "url": "https://karbein.com/images/Brand-photos.png"
          }
        }
      }
    `}
        </script>
      </Helmet>

      {loading ? (
        <Loading />
      ) : (
        <div className="bg-gray-50">
          <div
            style={{
              direction:
                i18n.language === "ku" || i18n.language === "ar"
                  ? "rtl"
                  : "ltr",
            }}
            className="flex auth-container justify-center items-center min-h-screen  pt-[80px] "
          >
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 w-[600px]">
              <div className="mx-auto max-w-lg">
                <form
                  onSubmit={submit}
                  className="mb-0 mt-6 space-y-4 rounded-lg p-4 shadow sm:p-6 lg:p-8 bg-white"
                >
                  <p className="text-center text-lg font-medium">
                    {t("Sign_account")}{" "}
                  </p>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-[5px] mx-[5px] text-sm font-medium text-gray-700"
                    >
                      {t("email")}{" "}
                    </label>
                    <div className="relative">
                      <input
                        type="email"
                        name="email"
                        className="w-full rounded-lg auth-input focus:border-gray-200 border-gray-200 p-3 pe-12 text-sm"
                        placeholder={t("email")}
                        value={user_name}
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </div>
                    <div className="ml-[5px] pt-[8px]">
                      {errors && errors.email && (
                        <div className="text-red-500">
                          {errors.email === "Please provide your email address."
                            ? t("Email_required")
                            : t("Email_not_found")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
               
                    <label
                      htmlFor="email"
                      className="block mb-[5px] mx-[5px] text-sm font-medium text-gray-700"
                    >
                      {t("Password")}{" "}
                      </label>
                    
                    <div className=" border rounded-lg  flex justify-between items-center focus:border-gray-200 border-gray-200">
                      <input
                        type={showPassword ? "text" : "password"}
                        name={t("Password")}
                        className="w-full rounded-lg auth-input border-none p-3 pe-12 text-sm"
                        placeholder= {t("Password")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        onClick={() => setShowPassword(!showPassword)}
                        style={{
                          marginLeft:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "15px"
                              : "0px",
                          marginRight:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "0px"
                              : "15px",
                        }}
                      >
                        {showPassword ? (
                          <RemoveRedEyeOutlinedIcon
                            style={{ fontSize: "25px" }}
                            className="text-gray-400 pl-[3px]"
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            style={{ fontSize: "25px" }}
                            className="text-gray-400 pl-[3px]"
                          />
                        )}
                      </div>
                    </div>
                    <div className="ml-[5px] pt-[8px]">
                  {errors && errors.password && (
                      <div className="text-red-500">
                        {errors.password === "The password field is required."
                          ? t("Password_required")
                          : t("Incorrect_password")}
                      </div>
                    )}
                  </div>
                  </div>
                 
                  <div className="text-right">
                    <a
                      href={"/forgot-password"}
                      className="text-sm text-gray-500 underline"
                    >
                      {t("Forgot_password")}{" "}
                    </a>
                  </div>
                  <button
                    type="submit"
                    className="block w-full rounded-lg bg-[#ffb923] px-5 py-3 text-sm font-medium text-white hover:bg-[#ffc64a]"
                  >
                    {t("Sign_in")}
                  </button>
                  <div className="text-center mt-4 flex flex-col gap-[5px]">
                    <p className="text-sm text-gray-500 mb-[15px]">
                      {t("Or")}{" "}
                    </p>
                    <a
                      href={loginUrl}
                      type="button"
                      className="flex hover:text-black items-center  justify-center w-full rounded-lg px-5 py-3 text-sm font-medium border text-gray-500  mb-2"
                    >
                      <FcGoogle
                        style={{
                          marginLeft:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "5px"
                              : "0px",
                          marginRight:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "0px"
                              : "5px",
                        }}
                        size={20}
                      />
                      {t("Login_with_Google")}{" "}
                    </a>
                    {/* <a
                      href={facebookUrl}
                      // onClick={handleFacebookLogin}
                      type="button"
                      className="flex items-center justify-center w-full rounded-lg bg-[#3b5998] px-5 py-3 text-sm font-medium text-white hover:bg-[#4c70ba] mb-2"
                    >
                      <FaFacebookF
                        style={{
                          marginLeft:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "5px"
                              : "0px",
                          marginRight:
                            i18n.language === "ku" || i18n.language === "ar"
                              ? "0px"
                              : "5px",
                        }}
                        size={20}
                      />
                      {t("Login_with_Facebook")}{" "}
                    </a> */}
                  </div>

                  <p className="text-center text-sm text-gray-500">
                    {t("Dont_have_account")}
                    <a href={"/Register"} className="underline">
                      {t("Sign_up")}{" "}
                    </a>
                  </p>
                </form>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default Login;
