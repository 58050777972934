import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  freelancer_details,
  freelancer_get,
  freelancer_insert,
  freelancer_update,
  freelancer_update_details,
} from "../../../redux/action/freelancer-action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SelectCurrency from "react-select-currency";
import { Link, useNavigate, useParams } from "react-router-dom";
import countryList from "react-select-country-list";
import { user_action } from "../../../redux/action/cv-action";
import Loading from "../../../sub component/show design data/loading";
import { services_update_details } from "../../../redux/action/services-action";
import HomeFooter from "../../../sub component/home/footer";
import Cookies from "js-cookie";
import {
  job_details,
  job_insert,
  job_update,
} from "../../../redux/action/job-action";
import Error404 from "../../error page/error404";
import { City, Country, State } from "country-state-city";
import Admin_nav from "../../../app bar/admin-nav";

const Admin_jobs_insert = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const options = useMemo(() => countryList().getData(), []);
  const user_data = useSelector((state) => state.cv.user);

  const [loading, setLoading] = useState(true);
  const [loading_data, setLoadingData] = useState(true);

  const [job_title, setJob_title] = useState("");

  const [industry, setIndustry] = useState("");
  // const [country, setCountry] = useState("");
  // const [state, setState] = useState("");
  // const [city, setCity] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [functionalArea, setFunctionalArea] = useState("");

  const [job_type, setJob_type] = useState("");

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [experience, setExperience] = useState("");
  const [salary, setSalary] = useState("");
  const [Certifications, setCertifications] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [Period, setPeriod] = useState("");
  const [currency, setCurrency] = useState("");
  const [gender, setGender] = useState("");

  const [Expire_Date, setExpire_Date] = useState("");

  const [description, setDescription] = useState("");

  const [skills, setSkills] = useState("");
  const [perHourPrice, setPerHourPrice] = useState("");
  const [actionsCompleted, setActionsCompleted] = useState(false);

  const data = useSelector((state) => state.job.job_details);

  //for image if ichanged image show in screen
  const [imageFile, setImageFile] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [image, setImage] = useState(data ? data.image : " ");

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file && file instanceof Blob) {
      setImageFile(file);
    } else {
    }
  };

  useEffect(() => {
    if (imageFile) {
      const url = URL.createObjectURL(imageFile);
      setImageUrl(url);

      // Clean up the object URL when the component unmounts or the imageFile changes
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [imageFile]);

  const currencies = [
    { code: "AED", name: "United Arab Emirates Dirham" },
    { code: "AFN", name: "Afghan Afghani" },
    { code: "ALL", name: "Albanian Lek" },
    { code: "AMD", name: "Armenian Dram" },
    { code: "ANG", name: "Netherlands Antillean Guilder" },
    { code: "AOA", name: "Angolan Kwanza" },
    { code: "ARS", name: "Argentine Peso" },
    { code: "AUD", name: "Australian Dollar" },
    { code: "AWG", name: "Aruban Florin" },
    { code: "AZN", name: "Azerbaijani Manat" },
    { code: "BAM", name: "Bosnia-Herzegovina Convertible Mark" },
    { code: "BBD", name: "Barbadian Dollar" },
    { code: "BDT", name: "Bangladeshi Taka" },
    { code: "BGN", name: "Bulgarian Lev" },
    { code: "BHD", name: "Bahraini Dinar" },
    { code: "BIF", name: "Burundian Franc" },
    { code: "BMD", name: "Bermudian Dollar" },
    { code: "BND", name: "Brunei Dollar" },
    { code: "BOB", name: "Bolivian Boliviano" },
    { code: "BRL", name: "Brazilian Real" },
    { code: "BSD", name: "Bahamian Dollar" },
    { code: "BTN", name: "Bhutanese Ngultrum" },
    { code: "BWP", name: "Botswana Pula" },
    { code: "BYN", name: "Belarusian Ruble" },
    { code: "BZD", name: "Belize Dollar" },
    { code: "CAD", name: "Canadian Dollar" },
    { code: "CDF", name: "Congolese Franc" },
    { code: "CHF", name: "Swiss Franc" },
    { code: "CLP", name: "Chilean Peso" },
    { code: "CNY", name: "Chinese Yuan" },
    { code: "COP", name: "Colombian Peso" },
    { code: "CRC", name: "Costa Rican Colón" },
    { code: "CUP", name: "Cuban Peso" },
    { code: "CVE", name: "Cape Verdean Escudo" },
    { code: "CZK", name: "Czech Koruna" },
    { code: "DJF", name: "Djiboutian Franc" },
    { code: "DKK", name: "Danish Krone" },
    { code: "DOP", name: "Dominican Peso" },
    { code: "DZD", name: "Algerian Dinar" },
    { code: "EGP", name: "Egyptian Pound" },
    { code: "ERN", name: "Eritrean Nakfa" },
    { code: "ETB", name: "Ethiopian Birr" },
    { code: "EUR", name: "Euro" },
    { code: "FJD", name: "Fijian Dollar" },
    { code: "FKP", name: "Falkland Islands Pound" },
    { code: "FOK", name: "Funko Coin" },
    { code: "GBP", name: "British Pound Sterling" },
    { code: "GEL", name: "Georgian Lari" },
    { code: "GGP", name: "Guernsey Pound" },
    { code: "GHS", name: "Ghanaian Cedi" },
    { code: "GIP", name: "Gibraltar Pound" },
    { code: "GMD", name: "Gambian Dalasi" },
    { code: "GNF", name: "Guinean Franc" },
    { code: "GTQ", name: "Guatemalan Quetzal" },
    { code: "GYD", name: "Guyanaese Dollar" },
    { code: "HKD", name: "Hong Kong Dollar" },
    { code: "HNL", name: "Honduran Lempira" },
    { code: "HRK", name: "Croatian Kuna" },
    { code: "HTG", name: "Haitian Gourde" },
    { code: "HUF", name: "Hungarian Forint" },
    { code: "IDR", name: "Indonesian Rupiah" },
    { code: "ILS", name: "Israeli New Shekel" },
    { code: "IMP", name: "Manx Pound" },
    { code: "INR", name: "Indian Rupee" },
    { code: "IQD", name: "Iraqi Dinar" },
    { code: "IRR", name: "Iranian Rial" },
    { code: "ISK", name: "Icelandic Króna" },
    { code: "JEP", name: "Jersey Pound" },
    { code: "JMD", name: "Jamaican Dollar" },
    { code: "JOD", name: "Jordanian Dinar" },
    { code: "JPY", name: "Japanese Yen" },
    { code: "KES", name: "Kenyan Shilling" },
    { code: "KGS", name: "Kyrgystani Som" },
    { code: "KHR", name: "Cambodian Riel" },
    { code: "KID", name: "Kid Laroi Coin" },
    { code: "KMF", name: "Comorian Franc" },
    { code: "KRW", name: "South Korean Won" },
    { code: "KWD", name: "Kuwaiti Dinar" },
    { code: "KYD", name: "Cayman Islands Dollar" },
    { code: "KZT", name: "Kazakhstani Tenge" },
    { code: "LAK", name: "Laotian Kip" },
    { code: "LBP", name: "Lebanese Pound" },
    { code: "LKR", name: "Sri Lankan Rupee" },
    { code: "LRD", name: "Liberian Dollar" },
    { code: "LSL", name: "Lesotho Loti" },
    { code: "LYD", name: "Libyan Dinar" },
    { code: "MAD", name: "Moroccan Dirham" },
    { code: "MDL", name: "Moldovan Leu" },
    { code: "MGA", name: "Malagasy Ariary" },
    { code: "MKD", name: "Macedonian Denar" },
    { code: "MMK", name: "Myanmar Kyat" },
    { code: "MNT", name: "Mongolian Tugrik" },
    { code: "MOP", name: "Macanese Pataca" },
    { code: "MRU", name: "Mauritanian Ouguiya" },
    { code: "MUR", name: "Mauritian Rupee" },
    { code: "MVR", name: "Maldivian Rufiyaa" },
    { code: "MWK", name: "Malawian Kwacha" },
    { code: "MXN", name: "Mexican Peso" },
    { code: "MYR", name: "Malaysian Ringgit" },
    { code: "MZN", name: "Mozambican Metical" },
    { code: "NAD", name: "Namibian Dollar" },
    { code: "NGN", name: "Nigerian Naira" },
    { code: "NIO", name: "Nicaraguan Córdoba" },
    { code: "NOK", name: "Norwegian Krone" },
    { code: "NPR", name: "Nepalese Rupee" },
    { code: "NZD", name: "New Zealand Dollar" },
    { code: "OMR", name: "Omani Rial" },
    { code: "PAB", name: "Panamanian Balboa" },
    { code: "PEN", name: "Peruvian Sol" },
    { code: "PGK", name: "Papua New Guinean Kina" },
    { code: "PHP", name: "Philippine Peso" },
    { code: "PKR", name: "Pakistani Rupee" },
    { code: "PLN", name: "Polish Zloty" },
    { code: "PYG", name: "Paraguayan Guarani" },
    { code: "QAR", name: "Qatari Rial" },
    { code: "RON", name: "Romanian Leu" },
    { code: "RSD", name: "Serbian Dinar" },
    { code: "RUB", name: "Russian Ruble" },
    { code: "RWF", name: "Rwandan Franc" },
    { code: "SAR", name: "Saudi Riyal" },
    { code: "SBD", name: "Solomon Islands Dollar" },
    { code: "SCR", name: "Seychellois Rupee" },
    { code: "SDG", name: "Sudanese Pound" },
    { code: "SEK", name: "Swedish Krona" },
    { code: "SGD", name: "Singapore Dollar" },
    { code: "SHP", name: "Saint Helena Pound" },
    { code: "SLL", name: "Sierra Leonean Leone" },
    { code: "SOS", name: "Somali Shilling" },
    { code: "SRD", name: "Surinamese Dollar" },
    { code: "SSP", name: "South Sudanese Pound" },
    { code: "STN", name: "São Tomé & Príncipe Dobra" },
    { code: "SYP", name: "Syrian Pound" },
    { code: "SZL", name: "Swazi Lilangeni" },
    { code: "THB", name: "Thai Baht" },
    { code: "TJS", name: "Tajikistani Somoni" },
    { code: "TMT", name: "Turkmenistani Manat" },
    { code: "TND", name: "Tunisian Dinar" },
    { code: "TOP", name: "Tongan Paʻanga" },
    { code: "TRY", name: "Turkish Lira" },
    { code: "TTD", name: "Trinidad & Tobago Dollar" },
    { code: "TVD", name: "Tuvalu Coin" },
    { code: "TWD", name: "New Taiwan Dollar" },
    { code: "TZS", name: "Tanzanian Shilling" },
    { code: "UAH", name: "Ukrainian Hryvnia" },
    { code: "UGX", name: "Ugandan Shilling" },
    { code: "USD", name: "United States Dollar" },
    { code: "UYU", name: "Uruguayan Peso" },
    { code: "UZS", name: "Uzbekistani Som" },
    { code: "VES", name: "Venezuelan Bolívar" },
    { code: "VND", name: "Vietnamese Dong" },
    { code: "VUV", name: "Vanuatu Vatu" },
    { code: "WST", name: "Samoan Tala" },
    { code: "XAF", name: "Central African CFA Franc" },
    { code: "XCD", name: "East Caribbean Dollar" },
    { code: "XDR", name: "Special Drawing Rights" },
    { code: "XOF", name: "West African CFA Franc" },
    { code: "XPF", name: "CFP Franc" },
    { code: "YER", name: "Yemeni Rial" },
    { code: "ZAR", name: "South African Rand" },
    { code: "ZMW", name: "Zambian Kwacha" },
    { code: "ZWL", name: "Zimbabwean Dollar" },
  ];

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  const submit = async (e) => {
    e.preventDefault();
    await dispatch(
      job_insert(
        imageFile,
        job_title,
        functionalArea,
        industry,
        skills,
        gender,
        selectedCountry.name,
        selectedState.name,
        selectedCity,
        company_name,
        job_type,
        phone,
        email,
        salary,
        Certifications,
        Period,
        currency,
        experience,
        description,
        t,
        navigate
      )
    );
  };

  const user_id = useSelector((state) => state.cv.user.id);
  const freelance_data = useSelector(
    (state) => state.freelancer.freelancer_get
  );
  const [findData, setfindData] = useState([]);

  useEffect(() => {
    setfindData(freelance_data);
  }, [freelance_data]);
  const user = findData.find((user) => user.user_id === `${user_id}`);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);
  //securete

  const token = useSelector((state) => state.cv.isAuthuntucated);

  const user_role = useSelector((state) => state.cv.user.role) || "";

  useEffect(() => {
    if (selectedCountry) {
      // Fetch states of the selected country
      const stateList = State.getStatesOfCountry(selectedCountry.isoCode).map(
        (state) => ({
          name: state.name,
          isoCode: state.isoCode,
        })
      );
      setStates(stateList);
      setSelectedState("");
      setCities([]);
    } else {
      setStates([]);
      setCities([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    // Fetch all countries
    const countryList = Country.getAllCountries().map((country) => ({
      name: country.name,
      isoCode: country.isoCode,
    }));
    setCountries(countryList);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      // Fetch states of the selected country
      const stateList = State.getStatesOfCountry(selectedCountry.isoCode).map(
        (state) => ({
          name: state.name,
          isoCode: state.isoCode,
        })
      );
      setStates(stateList);
      setSelectedState("");
      setCities([]);
    } else {
      setStates([]);
      setCities([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      // Fetch cities of the selected state
      const cityList = City.getCitiesOfState(
        selectedCountry.isoCode,
        selectedState.isoCode
      ).map((city) => ({
        name: city.name,
      }));
      setCities(cityList);
    } else {
      setCities([]);
    }
  }, [selectedState, selectedCountry]);

  const handleCountryChange = (e) => {
    const country = countries.find((c) => c.isoCode === e.target.value);
    setSelectedCountry(country);
    setSelectedState("");
    setSelectedCity("");
  };

  const handleStateChange = (e) => {
    const state = states.find((s) => s.isoCode === e.target.value);
    setSelectedState(state);
    setSelectedCity("");
  };
  if (!loading) {
    if (!token || user_role !== "admin") {
      return <Error404 />;
    }
  }
  return (
    <div className="flex h-screen ">
      {loading ? null : <Admin_nav />}

      <div className="flex flex-col  flex-1 overflow-y-auto ">
        {loading ? (
          <Loading />
        ) : (
          <div
            className="flex mb-[20px] justify-center min-w-[100%] pt-[100px] w-full"
            style={{
              direction:
                i18n.language === "ku" || i18n.language === "ar"
                  ? "rtl"
                  : "ltr",
            }}
          >
            <div className="main-freeLancer-container max-w-[900px] main-freeLancer-details-container min-w-[90%] flex justify-center gap-[40px]">
              {/* <div className="left-details-container">
              <div className="left-details border rounded h-[150px]">
                <div className="left-details-header text-[20px] bg-[#ffb923] h-[45px] text-white flex font-[500] border-top-[20px] justify-center items-center">
                  {t("update")}
                </div>
                <div className="mx-[20px] flex gap-[10px] flex-col text-[18px] justify-center my-[20px] max-w-[340px] break-words">
                  <p>
                    - <Link to={"/dmin-panel/jobs/add"}>{t("add_job")}</Link>
                  </p>
                  <p>
                    - <Link to={"/Admin-panel/jobs"}>{t("Jobs")}</Link>
                  </p>
                </div>
              </div>
            </div> */}
              <div className="main-card-container flex flex-col w-[900px] gap-[50px]">
                <div className="freeLancers_border max-w-[900px] min-w-[100%] border rounded min-h-[100px]">
                  <div className="freelancer-card-container">
                    <div className="add-input-group w-[100%] bg-gray-100 p-[40px] rounded">
                      <form onSubmit={submit}>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                          <div>
                            <label className="block mb-2 input_add_freelancer text-sm font-medium text-gray-900 dark:text-white">
                              {t("image")}{" "}
                            </label>
                            <input
                              className="block w-full pr-[15px] text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                              id="multiple_files"
                              type="file"
                              multiple
                              accept="image/*"
                              onChange={handleFileInputChange}
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="first_name"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              {t("company_name")}
                            </label>
                            <input
                              type="text"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("company_name")}
                              required
                              onChange={(e) => setCompany_name(e.target.value)}
                            />
                          </div>

                          <div>
                            <label
                              htmlFor="first_name"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              {t("Job_Title")}
                            </label>
                            <input
                              type="text"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("job title")}
                              required
                              value={job_title}
                              onChange={(e) => setJob_title(e.target.value)}
                            />
                          </div>

                          <div>
                            <label
                              htmlFor="first_name"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              {t("gender")}
                            </label>
                            <select
                              type="text"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("gender")}
                              required
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option value="">{t("gender")}</option>
                              <option value={"male"}>{t("male")}</option>
                              <option value={"female"}>{t("female")}</option>
                              <option value={"male, female"}>
                                {t("male")} , {t("female")}
                              </option>
                            </select>
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("phone_number")}
                            </label>
                            <PhoneInput
                              inputStyle={{
                                minHeight: "2.6rem",
                                border: "1px solid #d2d6dc",
                                backgroundColor: "#F9FAFB",
                                paddingLeft:
                                  i18n.language === "en" ? "100px" : null,
                                paddingRight:
                                  i18n.language === "ku" ||
                                  i18n.language === "ar"
                                    ? "100px"
                                    : "0px",
                                color: "#1a202c",
                                fontSize: "0.875rem",
                                borderRadius: "0.5rem",
                                direction:
                                  i18n.language === "ku" ||
                                  i18n.language === "ar"
                                    ? "rtl"
                                    : "ltr",
                                width: "100%",
                              }}
                              buttonStyle={{
                                borderRadius:
                                  i18n.language === "ku" ||
                                  i18n.language === "ar"
                                    ? "0px 0.5rem 0.5rem 0px"
                                    : "0.5rem 0px 0px 0.5rem",
                                border: "1px solid #d2d6dc",
                                width: "70px",
                                paddingRight:
                                  i18n.language === "ku" ||
                                  i18n.language === "ar"
                                    ? "30px"
                                    : "0px",
                              }}
                              inputClass="input_add_freelancer rtl-phone-input-button min-h-[50px] bg-gray-900 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              buttonClass="custom-button-class"
                              containerClass="custom-container-class"
                              dropdownClass="custom-dropdown-class"
                              onChange={setPhone}
                              placeholder={t("phone_number")}
                              value={phone}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("Email")}
                            </label>
                            <input
                              type="email"
                              id="company"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("Email")}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("Functional_Area")}
                            </label>
                            <input
                              type="text"
                              id="company"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("Functional_Area")}
                              required
                              value={functionalArea}
                              onChange={(e) =>
                                setFunctionalArea(e.target.value)
                              }
                            />
                          </div>
                          <div>
                            <label className="block mb-2 input_add_freelancer text-sm font-medium text-gray-900 dark:text-white">
                              {t("Country")}{" "}
                            </label>
                            <select
                              className=" input_add_freelancer  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              value={selectedCountry?.isoCode || ""}
                              onChange={handleCountryChange}
                            >
                              <option value="">{t("select_country")} </option>
                              {countries.map((country) => (
                                <option
                                  key={country.isoCode}
                                  value={country.isoCode}
                                >
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label className="block mb-2 input_add_freelancer text-sm font-medium text-gray-900 dark:text-white">
                              {t("State")}{" "}
                            </label>
                            <select
                              className=" input_add_freelancer  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              value={selectedState?.isoCode || ""}
                              onChange={handleStateChange}
                              disabled={!selectedCountry}
                            >
                              <option value="">{t("select_state")} </option>
                              {states.map((state) => (
                                <option
                                  key={state.isoCode}
                                  value={state.isoCode}
                                >
                                  {state.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div>
                            <label className="block mb-2 input_add_freelancer text-sm font-medium text-gray-900 dark:text-white">
                              {t("City")}{" "}
                            </label>
                            <select
                              className=" input_add_freelancer  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              value={selectedCity}
                              onChange={(e) => setSelectedCity(e.target.value)}
                              disabled={!selectedCountry}
                            >
                              <option value="">{t("select_city")} </option>
                              {cities.map((city) => (
                                <option key={city.name} value={city.name}>
                                  {city.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("Skills")}
                            </label>
                            <input
                              type="text"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("Skills")}
                              required
                              value={skills}
                              onChange={(e) => setSkills(e.target.value)}
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="experience"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              {t("experience")}
                            </label>
                            <select
                              type="number"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("experience")}
                              value={experience}
                              onChange={(e) => {
                                setExperience(e.target.value);
                              }}
                            >
                              <option value="">{t("experience")}</option>

                              <option value="1 to 3 years">
                                {t("one_to_3_years")}
                              </option>
                              <option value=" 4 to 6 years">
                                {t("four_to_6_years")}
                              </option>
                              <option value="More than 6 years">
                                {t("more_than_6")}
                              </option>
                            </select>
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("Industry")}
                            </label>
                            <input
                              type="text"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("Industry")}
                              required
                              value={industry}
                              onChange={(e) => {
                                setIndustry(e.target.value);
                              }}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("Job_Type")}{" "}
                            </label>
                            <select
                              type="text"
                              className=" input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("Job_Type")}
                              required
                              value={job_type}
                              onChange={(e) => {
                                setJob_type(e.target.value);
                              }}
                            >
                              <option value={t("Job_Type")}>
                                {t("Job_Type")}
                              </option>

                              <option value="Full-time">Full-time</option>
                              <option value="Part-time">Part-time</option>
                              <option value="Remote">Remote</option>
                              <option value="Seasonal">Seasonal</option>
                              <option value="Hourly">Hourly</option>
                              <option value="Volunteer">Volunteer</option>
                            </select>
                          </div>
                        </div>

                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("Salary")}
                            </label>
                            <input
                              type="text"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("Salary")}
                              value={salary}
                              onChange={(e) => {
                                setSalary(e.target.value);
                              }}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("Period")}
                            </label>
                            <input
                              type="text"
                              className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("Period")}
                              value={Period}
                              onChange={(e) => {
                                setPeriod(e.target.value);
                              }}
                            />
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("currency")}
                            </label>
                            <select
                              className=" input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              value={currency}
                              onChange={handleChange}
                            >
                              <option value="">{t("currency")} </option>
                              {currencies.map((currency) => (
                                <option
                                  key={currency.code}
                                  value={currency.code}
                                >
                                  {currency.name} ({currency.code})
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              {t("Certifications")}{" "}
                            </label>
                            <select
                              type="number"
                              className=" input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder={t("Certifications")}
                              required
                              onChange={(e) => {
                                setCertifications(e.target.value);
                              }}
                            >
                              <option value="">
                                {t("Certifications")}
                              </option>
                              <option value="Bachelor's Degree">
                                {t("bachelors")}{" "}
                              </option>
                              
                              <option value="Diploma or Bachelor's Degree">
                                {t("DiplomaOr_belechor")}{" "}
                              </option>


                              <option value="Master's Degree">
                                {t("master")}{" "}
                              </option>
                              <option value="Doctorate">
                                {t("Doctorate")}{" "}
                              </option>
                              <option value="No Certification Required">
                                {t("No_Certification_Required")}{" "}
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="mb-6">
                          <label
                            htmlFor="description"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                          >
                            {t("Description")}
                          </label>
                          <textarea
                            className="input_add_freelancer text-area border bg-gray-50"
                            placeholder={t("Description")}
                            required
                            value={description}
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </div>
                        <button
                          type="submit"
                          className="text-white bg-[#ffb923] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          {t("submit")}
                        </button>
                      </form>
                    </div>
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin_jobs_insert;
