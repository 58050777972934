import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Cookies from "js-cookie";
import axios from "axios";
import { user_action } from "../../redux/action/cv-action";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import Loading from "../../sub component/show design data/loading";
import HomeFooter from "../../sub component/home/footer";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import { user_payment } from "../../redux/action/auth-action";
import { useNavigate } from "react-router-dom";
import Verify_email from "../verify-email/verify-email";

const Monthly_plan = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(user_action());
      await dispatch(user_payment());
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const [paymentId, setPaymentId] = useState(null);
  const [status, setStatus] = useState(null);
  const [QrCode, setQrCode] = useState();
  const [PersonalPayment, setPersonalPayment] = useState();
  const [BisnussPayment, setBisnussPayment] = useState();

  const [Payloading, setPayloading] = useState(false); // Initialize loading state
  const [readableCode, setReadableCode] = useState();
  //is active he awaya user paray dawa yan na
  const is_Active = useSelector((state) => state.auth.is_Active);
  const plan_type = useSelector((state) => state.cv.user.plan_type);
  // console.log(plan_type,is_Active);

  const createPayment = async () => {
    if (is_Active) {
      if (plan_type === "monthly") {
        Swal.fire({
          title: t("actived_plan"),
          showClass: {
            popup: `
        animate__animated
        animate__fadeInUp
        animate__faster
      `,
          },
          hideClass: {
            popup: `
        animate__animated
        animate__fadeOutDown
        animate__faster
      `,
          },
          customClass: {
            confirmButton: "custom-button", // Apply custom CSS class to confirm button
            cancelButton: "custom-button", // Apply custom CSS class to cancel button if you have one
          },
        });
      } else {
        try {
          setPayloading(true); // Set loading to true when the function starts

          const token = Cookies.get("jwt");

          const response = await axios.post(
            `${process.env.REACT_APP_URL}/api/payment/create/monthly`,
            {
      
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const data = response.data.paymentData;
          setPaymentId(data.paymentId);
          setPersonalPayment(data.personalAppLink);
          setBisnussPayment(data.businessAppLink);
          localStorage.setItem("paymentId", data.paymentId);
          setQrCode(data.qrCode);
          setReadableCode(data.readableCode);
        } catch (error) {
          toast.error(t("Payment_failed"));
        } finally {
          setPayloading(false); // Set loading to false once the request is complete
        }
      }
    } else {
      try {
        setPayloading(true);
        const token = Cookies.get("jwt");

        const response = await axios.post(
          `${process.env.REACT_APP_URL}/api/payment/create/monthly`,
          {
           
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = response.data.paymentData;
        setPaymentId(data.paymentId);
        setPersonalPayment(data.personalAppLink);
        setBisnussPayment(data.businessAppLink);
        localStorage.setItem("paymentId", data.paymentId);
        setQrCode(data.qrCode);
        setReadableCode(data.readableCode);
      } catch (error) {
          toast.error(t("Payment_failed"));
      } finally {
        setPayloading(false); // Set loading to false once the request is complete
      }
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let intervalId; // Declare intervalId in the outer scope
    let alertShown = false; // Variable to ensure the alert is shown only once

    const checkPayment = async () => {
      try {
        const token = Cookies.get("jwt");
        const paymentId = localStorage.getItem("paymentId");

        if (!paymentId) {
          console.error("Payment ID not found in localStorage");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/payment/status/${paymentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Check if the status is PAID
        if (response.data.status === "PAID" && !alertShown) {
          toast.success(t("Payment_Successful"));
          setTimeout(() => {
            navigate(-1); // Navigate to /services/Profile after the timeout
          }, 3000);
          clearInterval(intervalId); // Stop the interval after the alert
          localStorage.removeItem("paymentId"); // Clean up local storage
          alertShown = true; // Ensure the alert is only shown once
        }
      } catch (error) {}
    };

    intervalId = setInterval(checkPayment, 7000); // Run checkPayment every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const { t, i18n } = useTranslation();
  const isUser_verified = useSelector(
    (state) => state.cv.user.email_verified_at
  );
  const token = useSelector((state) => state.cv.isAuthuntucated);
  if (!loading) {
    if (!token) {
      navigate("/login"); // Navigate to "/update" route if user found
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) :
      
      token?
      
      Payloading ? (
        <Loading />
      ) : isUser_verified === null ? (
        <Verify_email />
      ) : (
        <>
          <div
            className=" w-full mt-[70px] "
            style={{
              direction:
                i18n.language === "ku" || i18n.language === "ar"
                  ? "rtl"
                  : "ltr",
            }}
          >
            <ToastContainer />

            <div class="w-full flex justify-center  px-3 ">
              <div class="mx-auto mt-8  rounded-3xl ring-1 ring-gray-200 sm:mt-12 lg:mx-0 lg:flex lg:max-w-none">
                <div class="p-8 sm:p-10 lg:flex-auto">
                  <h1 class="text-2xl font-bold tracking-tight text-gray-900">
                    {" "}
                    {t("Monthly")}{" "}
                  </h1>
                  <p class="mt-6 text-base leading-7 text-gray-600">
                    {" "}
                    {t("monthly_info")}
                  </p>
                  <div class="mt-10 flex items-center gap-x-4">
                    <h1 class="text-2xl font-bold tracking-tight text-gray-900">
                      {" "}
                    </h1>
                    <div class="h-px flex-auto bg-gray-100"></div>
                  </div>
                  <p class="mt-6 text-base leading-7 text-gray-600">
                    {" "}
                    {t("fib_info")}{" "}
                  </p>
                  <ul class="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                    <li class="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        zoomAndPan="magnify"
                        viewBox="0 0 30 30.000001"
                        height="20"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                        id="IconChangeColor"
                      >
                        <defs>
                          <clipPath id="id1">
                            <path
                              d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                              clip-rule="nonzero"
                              id="mainIconPathAttribute"
                              fill="green"
                            ></path>
                          </clipPath>
                        </defs>
                        <g clip-path="url(#id1)">
                          <path
                            fill="green"
                            d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
                            fill-opacity="1"
                            fill-rule="nonzero"
                            id="mainIconPathAttribute"
                          ></path>
                        </g>
                      </svg>{" "}
                      <span class="font-bold mx-1">{t("Create_CV")}</span>
                    </li>
                    <li class="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        zoomAndPan="magnify"
                        viewBox="0 0 30 30.000001"
                        height="20"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                        id="IconChangeColor"
                      >
                        <defs>
                          <clipPath id="id1">
                            <path
                              d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                              clip-rule="nonzero"
                              id="mainIconPathAttribute"
                              fill="green"
                            ></path>
                          </clipPath>
                        </defs>
                        <g clip-path="url(#id1)">
                          <path
                            fill="green"
                            d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
                            fill-opacity="1"
                            fill-rule="nonzero"
                            id="mainIconPathAttribute"
                          ></path>
                        </g>
                      </svg>{" "}
                      <span class="font-bold mx-1">{t("add_lecturer")}</span>
                    </li>
                    <li class="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        zoomAndPan="magnify"
                        viewBox="0 0 30 30.000001"
                        height="20"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                        id="IconChangeColor"
                      >
                        <defs>
                          <clipPath id="id1">
                            <path
                              d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                              clip-rule="nonzero"
                              id="mainIconPathAttribute"
                              fill="green"
                            ></path>
                          </clipPath>
                        </defs>
                        <g clip-path="url(#id1)">
                          <path
                            fill="green"
                            d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
                            fill-opacity="1"
                            fill-rule="nonzero"
                            id="mainIconPathAttribute"
                          ></path>
                        </g>
                      </svg>{" "}
                      <span class="font-bold mx-1">{t("add_freelancer")}</span>
                    </li>
                    <li class="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        zoomAndPan="magnify"
                        viewBox="0 0 30 30.000001"
                        height="20"
                        preserveAspectRatio="xMidYMid meet"
                        version="1.0"
                        id="IconChangeColor"
                      >
                        <defs>
                          <clipPath id="id1">
                            <path
                              d="M 2.847656 4.792969 L 26.796875 4.792969 L 26.796875 24.390625 L 2.847656 24.390625 Z M 2.847656 4.792969 "
                              clip-rule="nonzero"
                              id="mainIconPathAttribute"
                              fill="green"
                            ></path>
                          </clipPath>
                        </defs>
                        <g clip-path="url(#id1)">
                          <path
                            fill="green"
                            d="M 11.078125 24.3125 L 2.847656 15.890625 L 6.128906 12.53125 L 11.078125 17.597656 L 23.519531 4.875 L 26.796875 8.230469 Z M 11.078125 24.3125 "
                            fill-opacity="1"
                            fill-rule="nonzero"
                            id="mainIconPathAttribute"
                          ></path>
                        </g>
                      </svg>{" "}
                      <span class="font-bold mx-1"> {t("Add_service")}</span>
                    </li>
                  </ul>
                  {QrCode ? (
                    <p className="mt-[40px] font-bold">
                      {t("payment_important")}
                    </p>
                  ) : (
                    <p className="mt-[40px] font-bold">{t("buy_help")}</p>
                  )}
                </div>

                <div class="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                  <div class="rounded-2xl bg-gray-50 py-10 h-full text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                    {QrCode ? (
                      <>
                        <img src={QrCode} alt="" className="h-full mx-auto " />
                        {/* <a href={PersonalPayment}>Pay with personal Acount</a>
<a href={BisnussPayment}>Pay with bisnuss Acount</a> */}
                        <p className="mt-[10px]">{readableCode}</p>
                        <div class="flex justify-center mt-[70px] mb-[-30px]">
                          <div class="flex sm:gap-3 gap-1">
                            <a
                              class="px-3 py-2 sm:text-[15px] text-[13px] min-w-[120px] text-center text-white bg-green-500  hover:bg-green-600 border border-violet-600 rounded"
                              href={PersonalPayment}
                            >
                              {t("Personal_account")}{" "}
                            </a>

                            <a
                              class="px-3 py-2  min-w-[120px] sm:text-[15px] text-[13px]   text-center text-green-500 border  rounded  hover:text-green-600  "
                              href={BisnussPayment}
                            >
                              {t("business_account")}{" "}
                            </a>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div class="mx-auto max-w-xs px-8">
                        {/* <p class="text-base font-semibold text-gray-600">
                          {" "}
                          {t("payment_monyBack")}
                        </p> */}
                        <p class="mt-6 mb-12 flex items-baseline justify-center gap-x-2">
                          <span class="text-5xl font-bold tracking-tight text-gray-900 ">
                            <span class="italic text-gray-700">3$</span>
                          </span>
                          <span class="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                            USD{" "}
                          </span>
                        </p>
                        <button
                          onClick={createPayment}
                          class="bg-[#ffb923] text-white rounded-md py-2 px-3 w-full block my-6"
                        >
                          {t("get_started")} →
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <button  onClick={cheakPayment} className="mt-[200px]">
 cheakPayment
 </button> */}
          </div>
          <HomeFooter />
        </>
      )
      :          <Loading />
}
    </>
  );
};

export default Monthly_plan;
