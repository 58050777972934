import React, { useEffect, useState } from "react";
import { EDUCATION, education } from "../../../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import Use_education_hook from "../../../hooks/cv-inputs-hook/education-hook";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import SearchIcon from "@rsuite/icons/Search";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { styled } from "@mui/material/styles";
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  // theme.palette.mode === 'dark'
  //   ? 'rgba(255, 255, 255, .05)'
  //   : 'rgba(0, 0, 0, .03)',
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const Education = () => {
  // js hook code
const [school, setSchool] = useState([]); // Initialize with an empty string
const [city, setCity] = useState([]);
const [start_date, setStart_date] = useState([]);
const [end_date, setEnd_date] = useState([]);
const [description, setDescription] = useState([]);

const [title_education, setTitle_education] = useState([]);
    const [inputCount, setInputCount] = useState(1);

// cv1_details
const cv1_data = useSelector((state) => state.cv.cv1_details);
const cv1_education_scholl = cv1_data ? cv1_data.education_scholl : "";
const cv1_education_city = cv1_data ? cv1_data.education_city : "";
const cv1_education_start_date = cv1_data ? cv1_data.education_start_date : "";
const cv1_education_end_date = cv1_data ? cv1_data.education_end_date : "";
const cv1_education_description = cv1_data ? cv1_data.education_description : "";

useEffect(() => {
    if (cv1_education_scholl || cv1_education_city || cv1_education_start_date || cv1_education_end_date || cv1_education_description) {
        const inputCount = Math.max(
            cv1_education_scholl.length,
            cv1_education_city.length,
            cv1_education_start_date.length,
            cv1_education_end_date.length,
            cv1_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv1_education_scholl); 
        setCity(cv1_education_city); 
        setStart_date(cv1_education_start_date);
        setEnd_date(cv1_education_end_date);
        setDescription(cv1_education_description);
    } 
}, [cv1_education_scholl, cv1_education_city, cv1_education_start_date, cv1_education_end_date, cv1_education_description]);

// cv2_details
const cv2_data = useSelector((state) => state.cv.cv2_details);
const cv2_education_scholl = cv2_data ? cv2_data.education_scholl : "";
const cv2_education_city = cv2_data ? cv2_data.education_city : "";
const cv2_education_start_date = cv2_data ? cv2_data.education_start_date : "";
const cv2_education_end_date = cv2_data ? cv2_data.education_end_date : "";
const cv2_education_description = cv2_data ? cv2_data.education_description : "";

useEffect(() => {
    if (cv2_education_scholl || cv2_education_city || cv2_education_start_date || cv2_education_end_date || cv2_education_description) {
        const inputCount = Math.max(
            cv2_education_scholl.length,
            cv2_education_city.length,
            cv2_education_start_date.length,
            cv2_education_end_date.length,
            cv2_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv2_education_scholl); 
        setCity(cv2_education_city); 
        setStart_date(cv2_education_start_date);
        setEnd_date(cv2_education_end_date);
        setDescription(cv2_education_description);
    } 
}, [cv2_education_scholl, cv2_education_city, cv2_education_start_date, cv2_education_end_date, cv2_education_description]);

// cv3_details
const cv3_data = useSelector((state) => state.cv.cv3_details);
const cv3_education_scholl = cv3_data ? cv3_data.education_scholl : "";
const cv3_education_city = cv3_data ? cv3_data.education_city : "";
const cv3_education_start_date = cv3_data ? cv3_data.education_start_date : "";
const cv3_education_end_date = cv3_data ? cv3_data.education_end_date : "";
const cv3_education_description = cv3_data ? cv3_data.education_description : "";

useEffect(() => {
    if (cv3_education_scholl || cv3_education_city || cv3_education_start_date || cv3_education_end_date || cv3_education_description) {
        const inputCount = Math.max(
            cv3_education_scholl.length,
            cv3_education_city.length,
            cv3_education_start_date.length,
            cv3_education_end_date.length,
            cv3_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv3_education_scholl); 
        setCity(cv3_education_city); 
        setStart_date(cv3_education_start_date);
        setEnd_date(cv3_education_end_date);
        setDescription(cv3_education_description);
    } 
}, [cv3_education_scholl, cv3_education_city, cv3_education_start_date, cv3_education_end_date, cv3_education_description]);

// Repeat this pattern for cv4_details to cv20_details, just changing the variable names accordingly
// cv4_details
const cv4_data = useSelector((state) => state.cv.cv4_details);
const cv4_education_scholl = cv4_data ? cv4_data.education_scholl : "";
const cv4_education_city = cv4_data ? cv4_data.education_city : "";
const cv4_education_start_date = cv4_data ? cv4_data.education_start_date : "";
const cv4_education_end_date = cv4_data ? cv4_data.education_end_date : "";
const cv4_education_description = cv4_data ? cv4_data.education_description : "";

useEffect(() => {
    if (cv4_education_scholl || cv4_education_city || cv4_education_start_date || cv4_education_end_date || cv4_education_description) {
        const inputCount = Math.max(
            cv4_education_scholl.length,
            cv4_education_city.length,
            cv4_education_start_date.length,
            cv4_education_end_date.length,
            cv4_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv4_education_scholl); 
        setCity(cv4_education_city); 
        setStart_date(cv4_education_start_date);
        setEnd_date(cv4_education_end_date);
        setDescription(cv4_education_description);
    } 
}, [cv4_education_scholl, cv4_education_city, cv4_education_start_date, cv4_education_end_date, cv4_education_description]);

// cv5_details
const cv5_data = useSelector((state) => state.cv.cv5_details);
const cv5_education_scholl = cv5_data ? cv5_data.education_scholl : "";
const cv5_education_city = cv5_data ? cv5_data.education_city : "";
const cv5_education_start_date = cv5_data ? cv5_data.education_start_date : "";
const cv5_education_end_date = cv5_data ? cv5_data.education_end_date : "";
const cv5_education_description = cv5_data ? cv5_data.education_description : "";

useEffect(() => {
    if (cv5_education_scholl || cv5_education_city || cv5_education_start_date || cv5_education_end_date || cv5_education_description) {
        const inputCount = Math.max(
            cv5_education_scholl.length,
            cv5_education_city.length,
            cv5_education_start_date.length,
            cv5_education_end_date.length,
            cv5_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv5_education_scholl); 
        setCity(cv5_education_city); 
        setStart_date(cv5_education_start_date);
        setEnd_date(cv5_education_end_date);
        setDescription(cv5_education_description);
    } 
}, [cv5_education_scholl, cv5_education_city, cv5_education_start_date, cv5_education_end_date, cv5_education_description]);

// cv6_details
const cv6_data = useSelector((state) => state.cv.cv6_details);
const cv6_education_scholl = cv6_data ? cv6_data.education_scholl : "";
const cv6_education_city = cv6_data ? cv6_data.education_city : "";
const cv6_education_start_date = cv6_data ? cv6_data.education_start_date : "";
const cv6_education_end_date = cv6_data ? cv6_data.education_end_date : "";
const cv6_education_description = cv6_data ? cv6_data.education_description : "";

useEffect(() => {
    if (cv6_education_scholl || cv6_education_city || cv6_education_start_date || cv6_education_end_date || cv6_education_description) {
        const inputCount = Math.max(
            cv6_education_scholl.length,
            cv6_education_city.length,
            cv6_education_start_date.length,
            cv6_education_end_date.length,
            cv6_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv6_education_scholl); 
        setCity(cv6_education_city); 
        setStart_date(cv6_education_start_date);
        setEnd_date(cv6_education_end_date);
        setDescription(cv6_education_description);
    } 
}, [cv6_education_scholl, cv6_education_city, cv6_education_start_date, cv6_education_end_date, cv6_education_description]);

// cv7_details
const cv7_data = useSelector((state) => state.cv.cv7_details);
const cv7_education_scholl = cv7_data ? cv7_data.education_scholl : "";
const cv7_education_city = cv7_data ? cv7_data.education_city : "";
const cv7_education_start_date = cv7_data ? cv7_data.education_start_date : "";
const cv7_education_end_date = cv7_data ? cv7_data.education_end_date : "";
const cv7_education_description = cv7_data ? cv7_data.education_description : "";

useEffect(() => {
    if (cv7_education_scholl || cv7_education_city || cv7_education_start_date || cv7_education_end_date || cv7_education_description) {
        const inputCount = Math.max(
            cv7_education_scholl.length,
            cv7_education_city.length,
            cv7_education_start_date.length,
            cv7_education_end_date.length,
            cv7_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv7_education_scholl); 
        setCity(cv7_education_city); 
        setStart_date(cv7_education_start_date);
        setEnd_date(cv7_education_end_date);
        setDescription(cv7_education_description);
    } 
}, [cv7_education_scholl, cv7_education_city, cv7_education_start_date, cv7_education_end_date, cv7_education_description]);

// cv8_details
const cv8_data = useSelector((state) => state.cv.cv8_details);
const cv8_education_scholl = cv8_data ? cv8_data.education_scholl : "";
const cv8_education_city = cv8_data ? cv8_data.education_city : "";
const cv8_education_start_date = cv8_data ? cv8_data.education_start_date : "";
const cv8_education_end_date = cv8_data ? cv8_data.education_end_date : "";
const cv8_education_description = cv8_data ? cv8_data.education_description : "";

useEffect(() => {
    if (cv8_education_scholl || cv8_education_city || cv8_education_start_date || cv8_education_end_date || cv8_education_description) {
        const inputCount = Math.max(
            cv8_education_scholl.length,
            cv8_education_city.length,
            cv8_education_start_date.length,
            cv8_education_end_date.length,
            cv8_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv8_education_scholl); 
        setCity(cv8_education_city); 
        setStart_date(cv8_education_start_date);
        setEnd_date(cv8_education_end_date);
        setDescription(cv8_education_description);
    } 
}, [cv8_education_scholl, cv8_education_city, cv8_education_start_date, cv8_education_end_date, cv8_education_description]);

// cv9_details
const cv9_data = useSelector((state) => state.cv.cv9_details);
const cv9_education_scholl = cv9_data ? cv9_data.education_scholl : "";
const cv9_education_city = cv9_data ? cv9_data.education_city : "";
const cv9_education_start_date = cv9_data ? cv9_data.education_start_date : "";
const cv9_education_end_date = cv9_data ? cv9_data.education_end_date : "";
const cv9_education_description = cv9_data ? cv9_data.education_description : "";

useEffect(() => {
    if (cv9_education_scholl || cv9_education_city || cv9_education_start_date || cv9_education_end_date || cv9_education_description) {
        const inputCount = Math.max(
            cv9_education_scholl.length,
            cv9_education_city.length,
            cv9_education_start_date.length,
            cv9_education_end_date.length,
            cv9_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv9_education_scholl); 
        setCity(cv9_education_city); 
        setStart_date(cv9_education_start_date);
        setEnd_date(cv9_education_end_date);
        setDescription(cv9_education_description);
    } 
}, [cv9_education_scholl, cv9_education_city, cv9_education_start_date, cv9_education_end_date, cv9_education_description]);

// cv10_details
const cv10_data = useSelector((state) => state.cv.cv10_details);
const cv10_education_scholl = cv10_data ? cv10_data.education_scholl : "";
const cv10_education_city = cv10_data ? cv10_data.education_city : "";
const cv10_education_start_date = cv10_data ? cv10_data.education_start_date : "";
const cv10_education_end_date = cv10_data ? cv10_data.education_end_date : "";
const cv10_education_description = cv10_data ? cv10_data.education_description : "";

useEffect(() => {
    if (cv10_education_scholl || cv10_education_city || cv10_education_start_date || cv10_education_end_date || cv10_education_description) {
        const inputCount = Math.max(
            cv10_education_scholl.length,
            cv10_education_city.length,
            cv10_education_start_date.length,
            cv10_education_end_date.length,
            cv10_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv10_education_scholl); 
        setCity(cv10_education_city); 
        setStart_date(cv10_education_start_date);
        setEnd_date(cv10_education_end_date);
        setDescription(cv10_education_description);
    } 
}, [cv10_education_scholl, cv10_education_city, cv10_education_start_date, cv10_education_end_date, cv10_education_description]);

// cv11_details
const cv11_data = useSelector((state) => state.cv.cv11_details);
const cv11_education_scholl = cv11_data ? cv11_data.education_scholl : "";
const cv11_education_city = cv11_data ? cv11_data.education_city : "";
const cv11_education_start_date = cv11_data ? cv11_data.education_start_date : "";
const cv11_education_end_date = cv11_data ? cv11_data.education_end_date : "";
const cv11_education_description = cv11_data ? cv11_data.education_description : "";

useEffect(() => {
    if (cv11_education_scholl || cv11_education_city || cv11_education_start_date || cv11_education_end_date || cv11_education_description) {
        const inputCount = Math.max(
            cv11_education_scholl.length,
            cv11_education_city.length,
            cv11_education_start_date.length,
            cv11_education_end_date.length,
            cv11_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv11_education_scholl); 
        setCity(cv11_education_city); 
        setStart_date(cv11_education_start_date);
        setEnd_date(cv11_education_end_date);
        setDescription(cv11_education_description);
    } 
}, [cv11_education_scholl, cv11_education_city, cv11_education_start_date, cv11_education_end_date, cv11_education_description]);

// cv12_details
const cv12_data = useSelector((state) => state.cv.cv12_details);
const cv12_education_scholl = cv12_data ? cv12_data.education_scholl : "";
const cv12_education_city = cv12_data ? cv12_data.education_city : "";
const cv12_education_start_date = cv12_data ? cv12_data.education_start_date : "";
const cv12_education_end_date = cv12_data ? cv12_data.education_end_date : "";
const cv12_education_description = cv12_data ? cv12_data.education_description : "";

useEffect(() => {
    if (cv12_education_scholl || cv12_education_city || cv12_education_start_date || cv12_education_end_date || cv12_education_description) {
        const inputCount = Math.max(
            cv12_education_scholl.length,
            cv12_education_city.length,
            cv12_education_start_date.length,
            cv12_education_end_date.length,
            cv12_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv12_education_scholl); 
        setCity(cv12_education_city); 
        setStart_date(cv12_education_start_date);
        setEnd_date(cv12_education_end_date);
        setDescription(cv12_education_description);
    } 
}, [cv12_education_scholl, cv12_education_city, cv12_education_start_date, cv12_education_end_date, cv12_education_description]);

// cv13_details
const cv13_data = useSelector((state) => state.cv.cv13_details);
const cv13_education_scholl = cv13_data ? cv13_data.education_scholl : "";
const cv13_education_city = cv13_data ? cv13_data.education_city : "";
const cv13_education_start_date = cv13_data ? cv13_data.education_start_date : "";
const cv13_education_end_date = cv13_data ? cv13_data.education_end_date : "";
const cv13_education_description = cv13_data ? cv13_data.education_description : "";

useEffect(() => {
    if (cv13_education_scholl || cv13_education_city || cv13_education_start_date || cv13_education_end_date || cv13_education_description) {
        const inputCount = Math.max(
            cv13_education_scholl.length,
            cv13_education_city.length,
            cv13_education_start_date.length,
            cv13_education_end_date.length,
            cv13_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv13_education_scholl); 
        setCity(cv13_education_city); 
        setStart_date(cv13_education_start_date);
        setEnd_date(cv13_education_end_date);
        setDescription(cv13_education_description);
    } 
}, [cv13_education_scholl, cv13_education_city, cv13_education_start_date, cv13_education_end_date, cv13_education_description]);

// cv14_details
const cv14_data = useSelector((state) => state.cv.cv14_details);
const cv14_education_scholl = cv14_data ? cv14_data.education_scholl : "";
const cv14_education_city = cv14_data ? cv14_data.education_city : "";
const cv14_education_start_date = cv14_data ? cv14_data.education_start_date : "";
const cv14_education_end_date = cv14_data ? cv14_data.education_end_date : "";
const cv14_education_description = cv14_data ? cv14_data.education_description : "";

useEffect(() => {
    if (cv14_education_scholl || cv14_education_city || cv14_education_start_date || cv14_education_end_date || cv14_education_description) {
        const inputCount = Math.max(
            cv14_education_scholl.length,
            cv14_education_city.length,
            cv14_education_start_date.length,
            cv14_education_end_date.length,
            cv14_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv14_education_scholl); 
        setCity(cv14_education_city); 
        setStart_date(cv14_education_start_date);
        setEnd_date(cv14_education_end_date);
        setDescription(cv14_education_description);
    } 
}, [cv14_education_scholl, cv14_education_city, cv14_education_start_date, cv14_education_end_date, cv14_education_description]);

// cv15_details
const cv15_data = useSelector((state) => state.cv.cv15_details);
const cv15_education_scholl = cv15_data ? cv15_data.education_scholl : "";
const cv15_education_city = cv15_data ? cv15_data.education_city : "";
const cv15_education_start_date = cv15_data ? cv15_data.education_start_date : "";
const cv15_education_end_date = cv15_data ? cv15_data.education_end_date : "";
const cv15_education_description = cv15_data ? cv15_data.education_description : "";

useEffect(() => {
    if (cv15_education_scholl || cv15_education_city || cv15_education_start_date || cv15_education_end_date || cv15_education_description) {
        const inputCount = Math.max(
            cv15_education_scholl.length,
            cv15_education_city.length,
            cv15_education_start_date.length,
            cv15_education_end_date.length,
            cv15_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv15_education_scholl); 
        setCity(cv15_education_city); 
        setStart_date(cv15_education_start_date);
        setEnd_date(cv15_education_end_date);
        setDescription(cv15_education_description);
    } 
}, [cv15_education_scholl, cv15_education_city, cv15_education_start_date, cv15_education_end_date, cv15_education_description]);

// cv16_details
const cv16_data = useSelector((state) => state.cv.cv16_details);
const cv16_education_scholl = cv16_data ? cv16_data.education_scholl : "";
const cv16_education_city = cv16_data ? cv16_data.education_city : "";
const cv16_education_start_date = cv16_data ? cv16_data.education_start_date : "";
const cv16_education_end_date = cv16_data ? cv16_data.education_end_date : "";
const cv16_education_description = cv16_data ? cv16_data.education_description : "";

useEffect(() => {
    if (cv16_education_scholl || cv16_education_city || cv16_education_start_date || cv16_education_end_date || cv16_education_description) {
        const inputCount = Math.max(
            cv16_education_scholl.length,
            cv16_education_city.length,
            cv16_education_start_date.length,
            cv16_education_end_date.length,
            cv16_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv16_education_scholl); 
        setCity(cv16_education_city); 
        setStart_date(cv16_education_start_date);
        setEnd_date(cv16_education_end_date);
        setDescription(cv16_education_description);
    } 
}, [cv16_education_scholl, cv16_education_city, cv16_education_start_date, cv16_education_end_date, cv16_education_description]);

// cv17_details
const cv17_data = useSelector((state) => state.cv.cv17_details);
const cv17_education_scholl = cv17_data ? cv17_data.education_scholl : "";
const cv17_education_city = cv17_data ? cv17_data.education_city : "";
const cv17_education_start_date = cv17_data ? cv17_data.education_start_date : "";
const cv17_education_end_date = cv17_data ? cv17_data.education_end_date : "";
const cv17_education_description = cv17_data ? cv17_data.education_description : "";

useEffect(() => {
    if (cv17_education_scholl || cv17_education_city || cv17_education_start_date || cv17_education_end_date || cv17_education_description) {
        const inputCount = Math.max(
            cv17_education_scholl.length,
            cv17_education_city.length,
            cv17_education_start_date.length,
            cv17_education_end_date.length,
            cv17_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv17_education_scholl); 
        setCity(cv17_education_city); 
        setStart_date(cv17_education_start_date);
        setEnd_date(cv17_education_end_date);
        setDescription(cv17_education_description);
    } 
}, [cv17_education_scholl, cv17_education_city, cv17_education_start_date, cv17_education_end_date, cv17_education_description]);

// cv18_details
const cv18_data = useSelector((state) => state.cv.cv18_details);
const cv18_education_scholl = cv18_data ? cv18_data.education_scholl : "";
const cv18_education_city = cv18_data ? cv18_data.education_city : "";
const cv18_education_start_date = cv18_data ? cv18_data.education_start_date : "";
const cv18_education_end_date = cv18_data ? cv18_data.education_end_date : "";
const cv18_education_description = cv18_data ? cv18_data.education_description : "";

useEffect(() => {
    if (cv18_education_scholl || cv18_education_city || cv18_education_start_date || cv18_education_end_date || cv18_education_description) {
        const inputCount = Math.max(
            cv18_education_scholl.length,
            cv18_education_city.length,
            cv18_education_start_date.length,
            cv18_education_end_date.length,
            cv18_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv18_education_scholl); 
        setCity(cv18_education_city); 
        setStart_date(cv18_education_start_date);
        setEnd_date(cv18_education_end_date);
        setDescription(cv18_education_description);
    } 
}, [cv18_education_scholl, cv18_education_city, cv18_education_start_date, cv18_education_end_date, cv18_education_description]);

// cv19_details
const cv19_data = useSelector((state) => state.cv.cv19_details);
const cv19_education_scholl = cv19_data ? cv19_data.education_scholl : "";
const cv19_education_city = cv19_data ? cv19_data.education_city : "";
const cv19_education_start_date = cv19_data ? cv19_data.education_start_date : "";
const cv19_education_end_date = cv19_data ? cv19_data.education_end_date : "";
const cv19_education_description = cv19_data ? cv19_data.education_description : "";

useEffect(() => {
    if (cv19_education_scholl || cv19_education_city || cv19_education_start_date || cv19_education_end_date || cv19_education_description) {
        const inputCount = Math.max(
            cv19_education_scholl.length,
            cv19_education_city.length,
            cv19_education_start_date.length,
            cv19_education_end_date.length,
            cv19_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv19_education_scholl); 
        setCity(cv19_education_city); 
        setStart_date(cv19_education_start_date);
        setEnd_date(cv19_education_end_date);
        setDescription(cv19_education_description);
    } 
}, [cv19_education_scholl, cv19_education_city, cv19_education_start_date, cv19_education_end_date, cv19_education_description]);

// cv20_details
const cv20_data = useSelector((state) => state.cv.cv20_details);
const cv20_education_scholl = cv20_data ? cv20_data.education_scholl : "";
const cv20_education_city = cv20_data ? cv20_data.education_city : "";
const cv20_education_start_date = cv20_data ? cv20_data.education_start_date : "";
const cv20_education_end_date = cv20_data ? cv20_data.education_end_date : "";
const cv20_education_description = cv20_data ? cv20_data.education_description : "";

useEffect(() => {
    if (cv20_education_scholl || cv20_education_city || cv20_education_start_date || cv20_education_end_date || cv20_education_description) {
        const inputCount = Math.max(
            cv20_education_scholl.length,
            cv20_education_city.length,
            cv20_education_start_date.length,
            cv20_education_end_date.length,
            cv20_education_description.length
        );

        setInputCount(inputCount);
        setSchool(cv20_education_scholl); 
        setCity(cv20_education_city); 
        setStart_date(cv20_education_start_date);
        setEnd_date(cv20_education_end_date);
        setDescription(cv20_education_description);
    } 
}, [cv20_education_scholl, cv20_education_city, cv20_education_start_date, cv20_education_end_date, cv20_education_description]);


 const handleSchoolChange = (index, e) => {
    const newSchool = [...school];
    newSchool[index] = e.target.value;
    setSchool(newSchool);
  }
  const handleCityChange1 = (index, e) => {
    const newCity = [...city];
    newCity[index] = e.target.value;
    setCity(newCity);
  };
  const handleStart_dateChange = (index, e) => {
    const Start_date = [...start_date];
    Start_date[index] = e.target.value;
    setStart_date(Start_date);
  };
  const handleEnd_dateChange = (index, e) => {
    const End_date = [...end_date];
    End_date[index] = e.target.value;
    setEnd_date(End_date);
  };
const handledeDescriptionChange = (index, e) => {
  const newDescription = [...description];
  newDescription[index] = e.target.value;
  setDescription(newDescription);
};

  const handleEducationChange = (index, e) => {
    const newEducation = [...title_education];
    newEducation[index] = e.target.value;
    setTitle_education(newEducation);
  };
    const addInputField = () => {
    setInputCount(inputCount + 1);
  };
 const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      education(
        title_education,
        school,
        city,
        start_date,
        end_date,
        description
      )
    );
  }, [
    title_education,
    school,
    city,
    start_date,
    end_date,
    description,
    dispatch,
  ]);
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { t, i18n } = useTranslation();

  return (
    // <Accordion collapseAll>
    //   <Accordion.Panel className="accordion-panel">
    //     <Accordion.Title className="acordion-title font-bold text-gray-900">
    //       Education
    //     </Accordion.Title>
    //     <Accordion.Content>
       
    //     </Accordion.Content>
    //   </Accordion.Panel>
    // </Accordion>
     <Accordion 
     className='py-[25px]'
     style={{ fontFamily: 'Poppins, sans-serif' }}
     expanded={expanded === "panel11"}
     onChange={handleChange("panel11")}
   >
     <AccordionSummary
       aria-controls="panel1d-content"
       id="panel1d-header"
       className='text-[30px]  cv-input-header font-[500] text-gray-700'
     >
{t("Education")}                       </AccordionSummary>
 <AccordionDetails   style={{
            direction:
              i18n.language === "ku" || i18n.language === "ar" ? "rtl" : "ltr",
          }}   className="p-4 cv-input-content">
 {[...Array(inputCount)].map((_, index) => (
            <div key={index}>
              <p className="text-[25px] mt-[20px]  font-[500] ">
              {t("Education")}    : {index + 1}
              </p>
              {/* <div className="fixed-height-input flex-1">
                <label
                  htmlFor="inputname1"
                  className="block text-gray-800 font-semibold text-sm"
                >
                  Education{" "}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="inputname1"
                    className="block w-full bg-gray-100 rounded-md py-1.5 px-2 ring-inset outline-none border-none h-[50px] ring-gray-400 focus:text-gray-800"
                    onChange={(e) => handleEducationChange(index, e)}
                  />
                </div>
              </div> */}
              {/*another input*/}
              <div className="flex mt-[20px]">
                <div className="fixed-height-input flex-1">
                  <label
                    htmlFor="inputname1"
                    className="block text-gray-800 font-semibold text-sm"
                  >
   {t("School")}                   </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="inputname1"
                      className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={(e) => handleSchoolChange(index, e)}
                  value={school[index] || ''} // Set value to skillsData[index] or an empty string if skillsData[index] is undefined
                    />
                  </div>
                </div>
                <div className="fixed-height-input flex-1 ml-2"    style={{
                marginRight:
                  i18n.language === "ku" || i18n.language === "ar" ? "10px" : "0px",
              }}>
                  <label
                    htmlFor="inputname2"
                    className="block text-gray-800 font-semibold text-sm"
                  >
                      {t("City")}        
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="inputname2"
                      className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={(e) => handleCityChange1(index, e)}
                  value={city[index] || ''}  // Set value to skillsData[index] or an empty string if skillsData[index] is undefined
                    />
                  </div>
                </div>
              </div>
              <div className="flex mt-[30px]">
                <div className="fixed-height-input flex-1">
                  <label
                    htmlFor="inputname1"
                    className="block text-gray-800 font-semibold text-sm"
                  >
                      {t("Start_date")}        
                      </label>
                  <div className="mt-2">
                    <select
                      type="text"
                      name="inputname2"
                      className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={(e) => handleStart_dateChange(index, e)}
                  value={start_date[index] || ''}
                    >
         <option value="">{t("years")}        
         </option>                      <option value="1980">1980</option>
                      <option value="1981">1981</option>
                      <option value="1982">1982</option>
                      <option value="1983">1983</option>
                      <option value="1984">1984</option>
                      <option value="1985">1985</option>
                      <option value="1986">1986</option>
                      <option value="1987">1987</option>
                      <option value="1988">1988</option>
                      <option value="1989">1989</option>
                      <option value="1990">1990</option>
                      <option value="1991">1991</option>
                      <option value="1992">1992</option>
                      <option value="1993">1993</option>
                      <option value="1994">1994</option>
                      <option value="1995">1995</option>
                      <option value="1996">1996</option>
                      <option value="1997">1997</option>
                      <option value="1998">1998</option>
                      <option value="1999">1999</option>
                      <option value="2000">2000</option>
                      <option value="2001">2001</option>
                      <option value="2002">2002</option>
                      <option value="2003">2003</option>
                      <option value="2004">2004</option>
                      <option value="2005">2005</option>
                      <option value="2006">2006</option>
                      <option value="2007">2007</option>
                      <option value="2008">2008</option>
                      <option value="2009">2009</option>
                      <option value="2010">2010</option>
                      <option value="2011">2011</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2024">2025</option>
                      <option value="2024">2026</option>
                      <option value="2024">2027</option>
                      <option value="2024">2028</option>
                      <option value="2024">2029</option>
                      <option value="2024">2030</option>

                    </select>
                  </div>
                </div>
                <div className="fixed-height-input flex-1 ml-2"    style={{
                marginRight:
                  i18n.language === "ku" || i18n.language === "ar" ? "10px" : "0px",
              }} >
                  <label
                    htmlFor="inputname2"
                    className="block text-gray-800 font-semibold text-sm"
                  >
                      {t("End_date")}        
                      </label>
                  <div className="mt-2">
                    <select
                      type="text"
                      name="inputname2"
                      className="input_add_freelancer bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onChange={(e) => handleEnd_dateChange(index, e)}
                  value={end_date[index] || ''}                    >
                      <option value="">{t("years")}        
                      </option>
                      <option value="1980">1980</option>
                      <option value="1981">1981</option>
                      <option value="1982">1982</option>
                      <option value="1983">1983</option>
                      <option value="1984">1984</option>
                      <option value="1985">1985</option>
                      <option value="1986">1986</option>
                      <option value="1987">1987</option>
                      <option value="1988">1988</option>
                      <option value="1989">1989</option>
                      <option value="1990">1990</option>
                      <option value="1991">1991</option>
                      <option value="1992">1992</option>
                      <option value="1993">1993</option>
                      <option value="1994">1994</option>
                      <option value="1995">1995</option>
                      <option value="1996">1996</option>
                      <option value="1997">1997</option>
                      <option value="1998">1998</option>
                      <option value="1999">1999</option>
                      <option value="2000">2000</option>
                      <option value="2001">2001</option>
                      <option value="2002">2002</option>
                      <option value="2003">2003</option>
                      <option value="2004">2004</option>
                      <option value="2005">2005</option>
                      <option value="2006">2006</option>
                      <option value="2007">2007</option>
                      <option value="2008">2008</option>
                      <option value="2009">2009</option>
                      <option value="2010">2010</option>
                      <option value="2011">2011</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2024">2025</option>
                      <option value="2024">2026</option>
                      <option value="2024">2027</option>
                      <option value="2024">2028</option>
                      <option value="2024">2029</option>
                      <option value="2024">2030</option>
                    </select>
                  </div>
                </div>
              </div>
              {/*another input*/}
              <h1 className="text-gray-800 Accordion-sub-title mt-[30px]">
              {t("Description")} 
              </h1>
              <div className="fixed-height-input">
                <textarea
                    className="input_add_freelancer h-[300px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => handledeDescriptionChange(index, e)}
                                    value={description[index] || ''}

                />
                {/*another select*/}
              </div>
            </div>
          ))}
          <button onClick={addInputField} className="add-icon min-w-[180px] mt-6 rounded">
          <AddIcon/>          <option value="">{t("Add_Education")}        
          </option>
          </button>
     
     </AccordionDetails>
   </Accordion>
  );
};

export default Education;
